/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Enrollment,
  EnrollmentFromJSON,
  EnrollmentToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 * A collection of active enrollments
 * @export
 * @interface EnrollmentInfo
 */
export interface EnrollmentInfo {
  /**
   * Enrollments that the current users' actors have permission to see.
   * @type {Array<Enrollment>}
   * @memberof EnrollmentInfo
   */
  enrollments: Array<Enrollment>
  /**
   *
   * @type {PaginationResponse}
   * @memberof EnrollmentInfo
   */
  pagination?: PaginationResponse
}

export function EnrollmentInfoFromJSON(json: any): EnrollmentInfo {
  return EnrollmentInfoFromJSONTyped(json, false)
}

export function EnrollmentInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enrollments: (json['enrollments'] as Array<any>).map(EnrollmentFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function EnrollmentInfoToJSON(value?: EnrollmentInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enrollments: (value.enrollments as Array<any>).map(EnrollmentToJSON),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
