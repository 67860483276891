/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * An accepted spot for the specified program to be filled by a student.
 * @export
 * @interface AcceptedEnrollmentInviteProgram
 */
export interface AcceptedEnrollmentInviteProgram {
  /**
   * Unique identifier for the program the invite is sent for.
   * @type {number}
   * @memberof AcceptedEnrollmentInviteProgram
   */
  programKey: number
  /**
   * Unique identifier for the chosen student to be enrolled in the specified program. Utilized for existing students within the P1 system. If the student does not exist in P1, `studentFirstName` will be used instead to create a new student.
   * @type {number}
   * @memberof AcceptedEnrollmentInviteProgram
   */
  studentKey?: number
  /**
   * The name of the student to be enrolled in the specified program. This property will be used if the student does not already exist in the P1 system. If the student does exist, specify the `studentKey`.
   * @type {string}
   * @memberof AcceptedEnrollmentInviteProgram
   */
  studentFirstName: string
}

export function AcceptedEnrollmentInviteProgramFromJSON(
  json: any
): AcceptedEnrollmentInviteProgram {
  return AcceptedEnrollmentInviteProgramFromJSONTyped(json, false)
}

export function AcceptedEnrollmentInviteProgramFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AcceptedEnrollmentInviteProgram {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    studentKey: !exists(json, 'studentKey') ? undefined : json['studentKey'],
    studentFirstName: json['studentFirstName'],
  }
}

export function AcceptedEnrollmentInviteProgramToJSON(
  value?: AcceptedEnrollmentInviteProgram | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    studentKey: value.studentKey,
    studentFirstName: value.studentFirstName,
  }
}
