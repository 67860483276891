/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PossibleNewOwner
 */
export interface PossibleNewOwner {
  /**
   * Unique identifier for the actor.
   * @type {number}
   * @memberof PossibleNewOwner
   */
  actorKey: number
  /**
   * Unique identifier for the user.
   * @type {number}
   * @memberof PossibleNewOwner
   */
  userKey: number
  /**
   * The first name of a user who can own the event
   * @type {string}
   * @memberof PossibleNewOwner
   */
  firstName: string
  /**
   * The last name of a user who can own the event
   * @type {string}
   * @memberof PossibleNewOwner
   */
  lastName: string
}

export function PossibleNewOwnerFromJSON(json: any): PossibleNewOwner {
  return PossibleNewOwnerFromJSONTyped(json, false)
}

export function PossibleNewOwnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PossibleNewOwner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: json['actorKey'],
    userKey: json['userKey'],
    firstName: json['firstName'],
    lastName: json['lastName'],
  }
}

export function PossibleNewOwnerToJSON(value?: PossibleNewOwner | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    userKey: value.userKey,
    firstName: value.firstName,
    lastName: value.lastName,
  }
}
