/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Address, AddressFromJSON, AddressToJSON } from './'

/**
 *
 * @export
 * @interface Community
 */
export interface Community {
  /**
   * Community unique identifier
   * @type {number}
   * @memberof Community
   */
  communityKey?: number
  /**
   * Name of the community.
   * @type {string}
   * @memberof Community
   */
  name: string
  /**
   * Unique region identifier for a foreign key relationship from community to region.
   * @type {number}
   * @memberof Community
   */
  regionsKey: number
  /**
   *
   * @type {Address}
   * @memberof Community
   */
  address: Address
  /**
   * The unique identifier of the actor of 'Support Representative' for this particular community
   * @type {number}
   * @memberof Community
   */
  actorKey: number
  /**
   * The unique identifier of the contractual model for a tutor. This model is configured at a community level and specifies how all tutors for a community's programs are hired.
   * @type {string}
   * @memberof Community
   */
  tutorModel: string
  /**
   * The percentage of fees given to the tutor, stored as a fraction i.e. 12.34% stored as 0.1234.
   * @type {number}
   * @memberof Community
   */
  feePercentage?: number
  /**
   * The hideFromPublicSearch prevents a community from being displayed on the marketing site.
   * @type {boolean}
   * @memberof Community
   */
  hideFromPublicSearch: boolean
}

export function CommunityFromJSON(json: any): Community {
  return CommunityFromJSONTyped(json, false)
}

export function CommunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Community {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: !exists(json, 'communityKey')
      ? undefined
      : json['communityKey'],
    name: json['name'],
    regionsKey: json['regionsKey'],
    address: AddressFromJSON(json['address']),
    actorKey: json['actorKey'],
    tutorModel: json['tutorModel'],
    feePercentage: !exists(json, 'feePercentage')
      ? undefined
      : json['feePercentage'],
    hideFromPublicSearch: json['hideFromPublicSearch'],
  }
}

export function CommunityToJSON(value?: Community | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    name: value.name,
    regionsKey: value.regionsKey,
    address: AddressToJSON(value.address),
    actorKey: value.actorKey,
    tutorModel: value.tutorModel,
    feePercentage: value.feePercentage,
    hideFromPublicSearch: value.hideFromPublicSearch,
  }
}
