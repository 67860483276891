/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  FetchBillingResponseEnrollment,
  FetchBillingResponseEnrollmentFromJSON,
  FetchBillingResponseEnrollmentToJSON,
  FetchBillingResponseLicensing,
  FetchBillingResponseLicensingFromJSON,
  FetchBillingResponseLicensingToJSON,
  ParentInvoiceInfo,
  ParentInvoiceInfoFromJSON,
  ParentInvoiceInfoToJSON,
  PaymentConfiguration,
  PaymentConfigurationFromJSON,
  PaymentConfigurationToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchBillingResponse
 */
export interface FetchBillingResponse {
  /**
   *
   * @type {FetchBillingResponseEnrollment}
   * @memberof FetchBillingResponse
   */
  enrollment?: FetchBillingResponseEnrollment
  /**
   *
   * @type {FetchBillingResponseLicensing}
   * @memberof FetchBillingResponse
   */
  licensing?: FetchBillingResponseLicensing
  /**
   * Various payment configurations that can be used to populate UI components.
   * @type {Array<PaymentConfiguration>}
   * @memberof FetchBillingResponse
   */
  configurations: Array<PaymentConfiguration>
  /**
   * Boolean indicating if ach payment is allowed for the director within a Region
   * @type {boolean}
   * @memberof FetchBillingResponse
   */
  achAllowed: boolean
  /**
   * List of invoices sent by a director or tutor.
   * @type {Array<ParentInvoiceInfo>}
   * @memberof FetchBillingResponse
   */
  parentInvoices?: Array<ParentInvoiceInfo>
}

export function FetchBillingResponseFromJSON(json: any): FetchBillingResponse {
  return FetchBillingResponseFromJSONTyped(json, false)
}

export function FetchBillingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchBillingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enrollment: !exists(json, 'enrollment')
      ? undefined
      : FetchBillingResponseEnrollmentFromJSON(json['enrollment']),
    licensing: !exists(json, 'licensing')
      ? undefined
      : FetchBillingResponseLicensingFromJSON(json['licensing']),
    configurations: (json['configurations'] as Array<any>).map(
      PaymentConfigurationFromJSON
    ),
    achAllowed: json['achAllowed'],
    parentInvoices: !exists(json, 'parentInvoices')
      ? undefined
      : (json['parentInvoices'] as Array<any>).map(ParentInvoiceInfoFromJSON),
  }
}

export function FetchBillingResponseToJSON(
  value?: FetchBillingResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enrollment: FetchBillingResponseEnrollmentToJSON(value.enrollment),
    licensing: FetchBillingResponseLicensingToJSON(value.licensing),
    configurations: (value.configurations as Array<any>).map(
      PaymentConfigurationToJSON
    ),
    achAllowed: value.achAllowed,
    parentInvoices:
      value.parentInvoices === undefined
        ? undefined
        : (value.parentInvoices as Array<any>).map(ParentInvoiceInfoToJSON),
  }
}
