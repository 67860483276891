/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AddInternationalLicensingPaymentRequestBody
 */
export interface AddInternationalLicensingPaymentRequestBody {
  /**
   * Unique identifier for the user account.
   * @type {number}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  userKey: number
  /**
   * The amount being paid. For Licensing payment
   * @type {number}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  amount: number
  /**
   * The amount of tax applied to this payment.
   * @type {number}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  tax?: number
  /**
   * A description stored on a payment that can be utilized during a manual payment entry, typically to describe the reason for a manual payment/credit entry.
   * @type {string}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  desc?: string
  /**
   * The sales order number provided by Acumatica that pertains to this specific payment.
   * @type {string}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  orderId?: string
  /**
   * The unique identifier for the Country Coordinator to whom the payment was applied.
   * @type {number}
   * @memberof AddInternationalLicensingPaymentRequestBody
   */
  actorKey: number
}

export function AddInternationalLicensingPaymentRequestBodyFromJSON(
  json: any
): AddInternationalLicensingPaymentRequestBody {
  return AddInternationalLicensingPaymentRequestBodyFromJSONTyped(json, false)
}

export function AddInternationalLicensingPaymentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddInternationalLicensingPaymentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userKey: json['userKey'],
    amount: json['amount'],
    tax: !exists(json, 'tax') ? undefined : json['tax'],
    desc: !exists(json, 'desc') ? undefined : json['desc'],
    orderId: !exists(json, 'orderId') ? undefined : json['orderId'],
    actorKey: json['actorKey'],
  }
}

export function AddInternationalLicensingPaymentRequestBodyToJSON(
  value?: AddInternationalLicensingPaymentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    userKey: value.userKey,
    amount: value.amount,
    tax: value.tax,
    desc: value.desc,
    orderId: value.orderId,
    actorKey: value.actorKey,
  }
}
