/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  TranscriptParchmentRequestStatus,
  TranscriptParchmentRequestStatusFromJSON,
  TranscriptParchmentRequestStatusToJSON,
} from './'

/**
 * An array of basic transcript information available to the calling user. More detailed information can be fetched using the provided keys.
 * @export
 * @interface TranscriptOverview
 */
export interface TranscriptOverview {
  /**
   * The unique identifier for the Transcript
   * @type {number}
   * @memberof TranscriptOverview
   */
  transcriptKey: number
  /**
   * The user assigned name for the transcript.
   * @type {string}
   * @memberof TranscriptOverview
   */
  transcriptName: string
  /**
   * The unique identifier for the student that this transcript is linked to.
   * @type {number}
   * @memberof TranscriptOverview
   */
  studentKey: number
  /**
   * The name for the student.
   * @type {string}
   * @memberof TranscriptOverview
   */
  studentName: string
  /**
   *
   * @type {TranscriptParchmentRequestStatus}
   * @memberof TranscriptOverview
   */
  requestStatus: TranscriptParchmentRequestStatus
  /**
   * The full name of the parent who owns the transcript.
   * @type {string}
   * @memberof TranscriptOverview
   */
  parentName?: string
  /**
   * The username of the parent who owns the transcript.
   * @type {string}
   * @memberof TranscriptOverview
   */
  parentUsername?: string
}

export function TranscriptOverviewFromJSON(json: any): TranscriptOverview {
  return TranscriptOverviewFromJSONTyped(json, false)
}

export function TranscriptOverviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TranscriptOverview {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transcriptKey: json['transcriptKey'],
    transcriptName: json['transcriptName'],
    studentKey: json['studentKey'],
    studentName: json['studentName'],
    requestStatus: TranscriptParchmentRequestStatusFromJSON(
      json['requestStatus']
    ),
    parentName: !exists(json, 'parentName') ? undefined : json['parentName'],
    parentUsername: !exists(json, 'parentUsername')
      ? undefined
      : json['parentUsername'],
  }
}

export function TranscriptOverviewToJSON(
  value?: TranscriptOverview | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transcriptKey: value.transcriptKey,
    transcriptName: value.transcriptName,
    studentKey: value.studentKey,
    studentName: value.studentName,
    requestStatus: TranscriptParchmentRequestStatusToJSON(value.requestStatus),
    parentName: value.parentName,
    parentUsername: value.parentUsername,
  }
}
