import React from 'react'
import { LedgerCell, LedgerRow } from '../../Table/LedgerTable'
import TableHeaders from '../../Interfaces/TableHeaders'
import { StudentInfo } from '../../../hooks/useBreakdownDataLicensingFee'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableContainer, TableHead } from '@mui/material'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import TableHeader from '../../Table/TableHeader'
import { Breakdown } from './Breakdown'

/**
 * breakdown variants will allow us to group the logic of the three possible views,
 * as well as their respective properties, this way we can build a breakdown with the style we need
 * and then provide it to the BreakdownLedgerContainer.
 *
 *  Preview:
 *  - is used to build a table with the subtotal of each Licensing Fee. (contains its own Headers)
 *  - note: The Preview Variant is a condensed view of the Normal Variant.
 *
 *  Balance:
 *  - is used to display a summary of the total paid, total due, and balance.
 *
 *  Normal:
 *  - used to build a table containing the name of the Fee to be used as the header, as well as a row for:
 *    - first students
 *    - additional students
 *    - subtotal
 *
 *    each row will show the fee amount, number of students and subtotal.
 *
 */

export enum BreakDownLedgerVariants {
  Preview = 'Preview',
  Balance = 'Balance',
  Normal = 'Normal',
}

interface NormalVariantProps {
  type: BreakDownLedgerVariants.Normal
  firstStudent: StudentInfo
  additionalStudent: StudentInfo
  subtotal: number
  headerLabel: string
  tableHeaders?: TableHeaders[]
  isChallenge?: boolean
}

interface PreviewVariantProps {
  type: BreakDownLedgerVariants.Preview
  rows: Array<{
    label: string
    numberStudents: number
    subtotal: number
    currencyCode: string
  }>
  isChallenge: boolean
}

interface BalanceVariantProps {
  type: BreakDownLedgerVariants.Balance
  totalOwed: number
  totalPaid: number
  balance: number
  currencyCode: string
}

export type BreakdownLedgerProps =
  | NormalVariantProps
  | PreviewVariantProps
  | BalanceVariantProps

interface BreakdownLedgerRow {
  content: string
  color?: 'main' | 'medium' | 'disabled' | 'green'
  colSpan?: number
}

const buildBreakdownLedgerRows = (
  firstElement: BreakdownLedgerRow,
  secondElement: BreakdownLedgerRow,
  thirdElement?: BreakdownLedgerRow,
  fourthElement?: BreakdownLedgerRow
): LedgerRow => {
  const cells: LedgerCell[] = [
    {
      content: firstElement.content,
      align: 'left',
      color: firstElement.color ?? 'medium',
      colSpan: firstElement.colSpan,
    },
    {
      content: secondElement.content,
      align: 'right',
      color: secondElement.color ?? 'medium',
      colSpan: secondElement.colSpan,
    },
  ]

  if (!!thirdElement && thirdElement?.content !== '') {
    cells.push({
      content: thirdElement.content,
      align: 'right',
      color: thirdElement?.color ?? 'medium',
      colSpan: thirdElement.colSpan,
    })
  }

  if (!!fourthElement && fourthElement?.content !== '') {
    cells.push({
      content: fourthElement.content,
      align: 'right',
      color: fourthElement?.color ?? 'medium',
      colSpan: fourthElement.colSpan,
    })
  }

  return {
    cells,
  }
}

/**
 * Proper use of BreakdownLedger:
 *
 * - use the BreakdownLedgerContainer which is a wrapper for all breakdowns (this component also receive the Headers as Prop)
 * - use the BreakdownLedger and provide a type of {@link BreakDownLedgerVariants}
 * - based on the selected type the component will provide us the required and optional properties
 *
 */

export const BreakdownLedger: React.FC<BreakdownLedgerProps> = (props) => {
  const { t } = useTranslation()

  if (props.type === BreakDownLedgerVariants.Preview) {
    const [
      licensingFeeApplication,
      firstSemesterLicensingTuition,
      secondSemesterLicensingTuition,
    ] = props.rows

    const licensingFeeApplicationRow = buildBreakdownLedgerRows(
      { content: licensingFeeApplication.label },
      { content: `${licensingFeeApplication.numberStudents}`, colSpan: 2 },
      {
        content: `${getLocaleCurrencyForAmount(
          licensingFeeApplication.subtotal,
          licensingFeeApplication.currencyCode
        )}`,
        color: 'green',
      }
    )

    const firstSemesterLicensingTuitionRow = buildBreakdownLedgerRows(
      { content: firstSemesterLicensingTuition.label },
      {
        content: `${firstSemesterLicensingTuition.numberStudents}`,
        colSpan: 2,
      },
      {
        content: `${getLocaleCurrencyForAmount(
          firstSemesterLicensingTuition.subtotal,
          firstSemesterLicensingTuition.currencyCode
        )}`,
        color: 'green',
      }
    )

    const secondSemesterLicensingTuitionRow = buildBreakdownLedgerRows(
      { content: secondSemesterLicensingTuition.label },
      {
        content: `${secondSemesterLicensingTuition.numberStudents}`,
        colSpan: 2,
      },
      {
        content: `${getLocaleCurrencyForAmount(
          secondSemesterLicensingTuition.subtotal,
          secondSemesterLicensingTuition.currencyCode
        )}`,
        color: 'green',
      }
    )

    const programBreakdownRows: LedgerRow[] = []

    licensingFeeApplication.subtotal > 0 &&
      programBreakdownRows.push(licensingFeeApplicationRow)

    firstSemesterLicensingTuition.subtotal > 0 &&
      programBreakdownRows.push(firstSemesterLicensingTuitionRow)

    secondSemesterLicensingTuition.subtotal > 0 &&
      props.isChallenge &&
      programBreakdownRows.push(secondSemesterLicensingTuitionRow)

    return (
      <Breakdown
        variant={BreakDownLedgerVariants.Preview}
        breakdownRows={programBreakdownRows}
      />
    )
  }
  if (props.type === BreakDownLedgerVariants.Balance) {
    const totalOwedRow = buildBreakdownLedgerRows(
      { content: t('BreakdownLedger.Balance.Row.TotalOwed', 'Total Owed') },
      {
        content: `${getLocaleCurrencyForAmount(
          props.totalOwed,
          props.currencyCode
        )}`,
        colSpan: 3,
      }
    )

    const totalPaidRow = buildBreakdownLedgerRows(
      {
        content: t('BreakdownLedger.Balance.Row.TotalPaid', 'Total Paid'),
        color: 'main',
      },
      {
        content: `${props.totalPaid > 0 ? '-' : ''}${getLocaleCurrencyForAmount(
          props.totalPaid,
          props.currencyCode
        )}`,
        color: 'main',
        colSpan: 3,
      }
    )

    const balanceRow = buildBreakdownLedgerRows(
      {
        content: t('BreakdownLedger.Balance.Row.Balance', 'Balance'),
      },
      {
        content: `${getLocaleCurrencyForAmount(
          props.balance,
          props.currencyCode
        )}`,
        color: 'green',
        colSpan: 3,
      },
      { content: 'Does not include tax'}
    )

    const balanceRows: LedgerRow[] = [totalOwedRow, totalPaidRow, balanceRow]

    return (
      <Breakdown
        breakdownRows={balanceRows}
        variant={BreakDownLedgerVariants.Balance}
      />
    )
  }

  const firstStudentRow = buildBreakdownLedgerRows(
    {
      content: t('BreakdownLedger.Normal.Row.FirstStudents', 'First Students'),
    },
    {
      content: `${getLocaleCurrencyForAmount(
        props.firstStudent.feeAmount,
        props.firstStudent.currencyCode
      )}`,
    },
    { content: `${props.firstStudent.numberOfStudents}` },
    {
      content: `${getLocaleCurrencyForAmount(
        props.firstStudent.studentInfoSubTotal,
        props.firstStudent.currencyCode
      )}`,
    }
  )

  const additionalStudentRow = buildBreakdownLedgerRows(
    {
      content: t(
        'BreakdownLedger.Normal.Row.AdditionalStudents',
        'Additional Students'
      ),
    },
    {
      content: `${getLocaleCurrencyForAmount(
        props.additionalStudent.feeAmount,
        props.additionalStudent.currencyCode
      )}`,
    },
    { content: `${props.additionalStudent.numberOfStudents}` },
    {
      content: `${getLocaleCurrencyForAmount(
        props.additionalStudent.studentInfoSubTotal,
        props.additionalStudent.currencyCode
      )}`,
    }
  )

  const subtotalRow = buildBreakdownLedgerRows(
    {
      content: t('BreakdownLedger.Normal.Row.Subtotal', 'Subtotal'),
      color: 'main',
    },
    {
      content: `${getLocaleCurrencyForAmount(
        props.subtotal,
        props.firstStudent.currencyCode
      )}`,
      color: 'green',
      colSpan: 3,
    }
  )

  // add row to breakdown only when subtotal is greater than zero
  const programBreakdownRows: LedgerRow[] = []

  props.firstStudent.studentInfoSubTotal > 0 &&
    programBreakdownRows.push(firstStudentRow)

  props.additionalStudent.studentInfoSubTotal > 0 &&
    programBreakdownRows.push(additionalStudentRow)

  programBreakdownRows.push(subtotalRow)

  return (
    <Breakdown
      breakdownRows={programBreakdownRows}
      headerLabel={props.headerLabel}
      variant={BreakDownLedgerVariants.Normal}
    />
  )
}

interface BreakdownLedgerContainerProps {
  children: JSX.Element | JSX.Element[]
  headers?: TableHeaders[]
}

export const BreakdownLedgerContainer: React.FC<
  BreakdownLedgerContainerProps
> = (props) => {
  return (
    <TableContainer>
      <Table stickyHeader>
        {props.headers && (
          <TableHead>
            <TableHeader tableHeaders={props.headers} />
          </TableHead>
        )}
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  )
}
