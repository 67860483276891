/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 *
 * @export
 * @interface TuitionPayments
 */
export interface TuitionPayments {
  /**
   * The unique identifier for the student the tuition payment is for
   * @type {number}
   * @memberof TuitionPayments
   */
  studentKey: number
  /**
   * The unique identifier for the program the tuition payment is for
   * @type {number}
   * @memberof TuitionPayments
   */
  programKey: number
  /**
   *
   * @type {CashAmount}
   * @memberof TuitionPayments
   */
  amount: CashAmount
  /**
   * Designates who the payment is paid towards, the tutor or director.
   * @type {string}
   * @memberof TuitionPayments
   */
  invoiceOriginatorKey: TuitionPaymentsInvoiceOriginatorKeyEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TuitionPaymentsInvoiceOriginatorKeyEnum {
  Director = 'director',
  Tutor = 'tutor',
}

export function TuitionPaymentsFromJSON(json: any): TuitionPayments {
  return TuitionPaymentsFromJSONTyped(json, false)
}

export function TuitionPaymentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPayments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    amount: CashAmountFromJSON(json['amount']),
    invoiceOriginatorKey: json['invoiceOriginatorKey'],
  }
}

export function TuitionPaymentsToJSON(value?: TuitionPayments | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    amount: CashAmountToJSON(value.amount),
    invoiceOriginatorKey: value.invoiceOriginatorKey,
  }
}
