/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Credentials, CredentialsFromJSON, CredentialsToJSON } from './'

/**
 *
 * @export
 * @interface LoginForInviteRequestBody
 */
export interface LoginForInviteRequestBody {
  /**
   *
   * @type {Credentials}
   * @memberof LoginForInviteRequestBody
   */
  credentials: Credentials
  /**
   * The type of registration: agreement or family.
   * @type {string}
   * @memberof LoginForInviteRequestBody
   */
  signUpType: LoginForInviteRequestBodySignUpTypeEnum
  /**
   * The unique identifier tied to the signUpType. This will be used to ensure that the requirements to bring a user into the system are met. For agreement, this will be the agreementId and used to ensure the agreement is active.
   * @type {string}
   * @memberof LoginForInviteRequestBody
   */
  typeIdentifier: string
}

/**
 * @export
 * @enum {string}
 */
export enum LoginForInviteRequestBodySignUpTypeEnum {
  Agreement = 'agreement',
  Family = 'family',
}

export function LoginForInviteRequestBodyFromJSON(
  json: any
): LoginForInviteRequestBody {
  return LoginForInviteRequestBodyFromJSONTyped(json, false)
}

export function LoginForInviteRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoginForInviteRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    credentials: CredentialsFromJSON(json['credentials']),
    signUpType: json['signUpType'],
    typeIdentifier: json['typeIdentifier'],
  }
}

export function LoginForInviteRequestBodyToJSON(
  value?: LoginForInviteRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    credentials: CredentialsToJSON(value.credentials),
    signUpType: value.signUpType,
    typeIdentifier: value.typeIdentifier,
  }
}
