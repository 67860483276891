/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Address, AddressFromJSON, AddressToJSON } from './'

/**
 *
 * @export
 * @interface UserProfileEditBody
 */
export interface UserProfileEditBody {
  /**
   * The user ID assigned by the system when the user was first created.
   * @type {number}
   * @memberof UserProfileEditBody
   */
  id: number
  /**
   * The personal email the user registered with.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  username?: string
  /**
   * The first name provided by the user.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  firstName?: string
  /**
   * The last name provided by the user.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  lastName?: string
  /**
   * The phone number provided by the user.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  phone?: string
  /**
   *
   * @type {Address}
   * @memberof UserProfileEditBody
   */
  address?: Address
  /**
   * An official, CC-assigned and -managed email address. This cannot be set or changed by most users.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  email?: string
  /**
   * The user’s self-description.
   * @type {string}
   * @memberof UserProfileEditBody
   */
  bio?: string
  /**
   * Indicates if the user has marked his/ her profile as private.
   * @type {boolean}
   * @memberof UserProfileEditBody
   */
  isProfilePrivate?: boolean
  /**
   * Indicates if the user has marked his/ her login email as private.
   * @type {boolean}
   * @memberof UserProfileEditBody
   */
  isLoginEmailPrivate?: boolean
  /**
   * Indicates if the user has marked his/ her phone number as private.
   * @type {boolean}
   * @memberof UserProfileEditBody
   */
  isPhoneNumberPrivate?: boolean
  /**
   * The user’s country of residence
   * @type {string}
   * @memberof UserProfileEditBody
   */
  countryOfResidence?: string
  /**
   * The user’s country of citizenship
   * @type {string}
   * @memberof UserProfileEditBody
   */
  countryOfCitizenship?: string
}

export function UserProfileEditBodyFromJSON(json: any): UserProfileEditBody {
  return UserProfileEditBodyFromJSONTyped(json, false)
}

export function UserProfileEditBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileEditBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    username: !exists(json, 'username') ? undefined : json['username'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    address: !exists(json, 'address')
      ? undefined
      : AddressFromJSON(json['address']),
    email: !exists(json, 'email') ? undefined : json['email'],
    bio: !exists(json, 'bio') ? undefined : json['bio'],
    isProfilePrivate: !exists(json, 'isProfilePrivate')
      ? undefined
      : json['isProfilePrivate'],
    isLoginEmailPrivate: !exists(json, 'isLoginEmailPrivate')
      ? undefined
      : json['isLoginEmailPrivate'],
    isPhoneNumberPrivate: !exists(json, 'isPhoneNumberPrivate')
      ? undefined
      : json['isPhoneNumberPrivate'],
    countryOfResidence: !exists(json, 'countryOfResidence')
      ? undefined
      : json['countryOfResidence'],
    countryOfCitizenship: !exists(json, 'countryOfCitizenship')
      ? undefined
      : json['countryOfCitizenship'],
  }
}

export function UserProfileEditBodyToJSON(
  value?: UserProfileEditBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    username: value.username,
    firstName: value.firstName,
    lastName: value.lastName,
    phone: value.phone,
    address: AddressToJSON(value.address),
    email: value.email,
    bio: value.bio,
    isProfilePrivate: value.isProfilePrivate,
    isLoginEmailPrivate: value.isLoginEmailPrivate,
    isPhoneNumberPrivate: value.isPhoneNumberPrivate,
    countryOfResidence: value.countryOfResidence,
    countryOfCitizenship: value.countryOfCitizenship,
  }
}
