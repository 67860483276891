/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  Countries,
  CountriesFromJSON,
  States,
  StatesFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchStatesRequest extends runtime.BaseRequestParameters {
  countryCode: string
}

export interface ValidateAddressRequest extends runtime.BaseRequestParameters {
  disableGeocoding?: boolean
  disableAddressVerification?: boolean
  body?: Address
}

/**
 *
 */
export class AddressApi extends runtime.BaseAPI {
  /**
   * List of country codes and the full name of countries.
   */
  async fetchCountriesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Countries>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/countries`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CountriesFromJSON(jsonValue)
    )
  }

  /**
   * List of country codes and the full name of countries.
   */
  async fetchCountries(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Countries> {
    const response = await this.fetchCountriesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns code and full name of all available international countries.
   */
  async fetchInternationalCountriesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Countries>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/countries/international`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CountriesFromJSON(jsonValue)
    )
  }

  /**
   * Returns code and full name of all available international countries.
   */
  async fetchInternationalCountries(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Countries> {
    const response = await this.fetchInternationalCountriesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * List of states codes and names for a given country code.
   */
  async fetchStatesRaw(
    requestParameters: FetchStatesRequest
  ): Promise<runtime.ApiResponse<States>> {
    if (
      requestParameters.countryCode === null ||
      requestParameters.countryCode === undefined
    ) {
      throw new runtime.RequiredError(
        'countryCode',
        'Required parameter requestParameters.countryCode was null or undefined when calling fetchStates.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/states/{countryCode}`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(requestParameters.countryCode))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StatesFromJSON(jsonValue)
    )
  }

  /**
   * List of states codes and names for a given country code.
   */
  async fetchStates(requestParameters: FetchStatesRequest): Promise<States> {
    const response = await this.fetchStatesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Use this endpoint to validate an address that will be submitted as part of a resource with an `address` field. To do so, submit the proposed address. The response will comprise valid options for the user to select. If the response is empty, then they must edit their address till there is a result to select.
   * Receive any needed corrections to an address that will be submitted as part of a Community, Program, or User.
   */
  async validateAddressRaw(
    requestParameters: ValidateAddressRequest
  ): Promise<runtime.ApiResponse<Array<Address>>> {
    const queryParameters: any = {}

    if (requestParameters.disableGeocoding !== undefined) {
      queryParameters['disableGeocoding'] = requestParameters.disableGeocoding
    }

    if (requestParameters.disableAddressVerification !== undefined) {
      queryParameters['disableAddressVerification'] =
        requestParameters.disableAddressVerification
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/address/validate`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AddressToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AddressFromJSON)
    )
  }

  /**
   * Use this endpoint to validate an address that will be submitted as part of a resource with an `address` field. To do so, submit the proposed address. The response will comprise valid options for the user to select. If the response is empty, then they must edit their address till there is a result to select.
   * Receive any needed corrections to an address that will be submitted as part of a Community, Program, or User.
   */
  async validateAddress(
    requestParameters: ValidateAddressRequest
  ): Promise<Array<Address>> {
    const response = await this.validateAddressRaw(requestParameters)
    return await response.value()
  }
}
