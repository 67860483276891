import React, { ComponentType, Context, PropsWithChildren } from 'react'
import { browserRouterType, testRouterType } from '../Providers'
import { AppRenderOptions } from '../../../testRenderer'
import AuthProvider, { AuthContext } from '../../Routes/AuthProvider'
import FilterProvider, { FilterContext } from '../FilterContext'
import CommunitiesProvider, { CommunitiesContext } from '../CommunitiesContext'
import SnackbarProvider, { SnackbarContext } from '../SnackbarContext'
import LearningCenterProvider, {
  LearningCenterContext,
} from '../LearningCenterContext'
import BusinessProvider, { BusinessContext } from '../BusinessContext'
import NotistackSnackbarKeyProvider, {
  NotistackSnackbarKeyContext,
} from '../NotistackSnackbarKeyProvider'
import SpeedDialMenuProvider, {
  SpeedDialMenuContext,
} from '../SpeedDialMenuContext'
import CountryProvider, { CountryContext } from '../CountryContext'
import InviteProvider, { InviteContext } from '../InviteContext'
import { LoadingContext, LoadingProvider } from '../LoadingContext'
import ProgramDetailsProvider, {
  ProgramDetailsContext,
} from '../ProgramDetailsContext'
import AccountProvider, { AccountContext } from '../AccountContext'
import DashboardProvider, { DashboardContext } from '../DashboardContext'
import { EventContext, EventProvider } from '../EventContext'
import { TranscriptContext, TranscriptProvider } from '../TranscriptContext'
import TitleContext, { TitleProvider } from '../../../TitleContext'
import { UserContext, UserProvider } from '../../../UserContext'
import { ScrollContext, ScrollContextProvider } from '../ScrollContext'
import { Router } from '@remix-run/router'

type ProviderConfig<T, P = Record<string, unknown>> = {
  Provider: ComponentType<PropsWithChildren<P>>
  Context: Context<T>
  /** The COMPLETE set of values in a context's list of values */
  mockValue?: T
  /** When you want to use the context but override a few values */
  testConfig?: P
  /** Presently only used for NotistackSnackbarKeyProvider */
  router?: Router
  /** Whether or not a Provider should be mocked. Generally unused except when testing Contexts */
  shouldMock?: boolean
}

type ProviderEntry<T, P = Record<string, unknown>> = {
  key: string
  config: ProviderConfig<T, P>
  dependencies?: string[]
}

/**
 * Complex? No
 *
 * Use:
 * - Create the class, one per each adjacent set of Providers, see createPrivateProviders
 * - addProvider with appropriate props
 * - return each composition as `composition.createComposedProvider()`
 * - profit
 */
class ProviderComposition {
  constructor(
    private isTestEnv: boolean = process.env.NODE_ENV === 'test',
    private providers: Map<
      string,
      ProviderEntry<unknown, Record<string, unknown>>
    > = new Map()
  ) {}

  addProvider<T, P = Record<string, unknown>>({
    key,
    config,
    dependencies = [],
  }: {
    key: string
    config: ProviderConfig<T, P>
    dependencies: string[]
  }): void {
    const entry: ProviderEntry<T, P> = { key, config, dependencies }
    this.providers.set(
      key,
      entry as ProviderEntry<unknown, Record<string, unknown>>
    )
  }

  createComposedProvider(): React.FC<PropsWithChildren> {
    return ({ children }) => {
      const sortedProviders = this.topologicalSort()

      return sortedProviders.reduce((wrapped, { config }) => {
        const {
          Provider,
          Context,
          mockValue,
          testConfig,
          router,
          shouldMock = this.isTestEnv,
        } = config

        if (shouldMock && mockValue !== undefined) {
          return (
            <Context.Provider value={mockValue}>{wrapped}</Context.Provider>
          )
        }

        return (
          <Provider {...testConfig} router={router}>
            {wrapped}
          </Provider>
        )
      }, <>{children}</>)
    }
  }

  private topologicalSort(): ProviderEntry<unknown, Record<string, unknown>>[] {
    const visited = new Set<string>()
    const result: ProviderEntry<unknown, Record<string, unknown>>[] = []

    const visit = (key: string) => {
      if (visited.has(key)) return

      const entry = this.providers.get(key)
      if (!entry) return

      visited.add(key)

      entry.dependencies?.forEach(visit)
      result.push(entry)
    }

    this.providers.forEach((_, key) => visit(key))
    return result
  }
}

/**
 * Use when desiring to wrap components only. There is some capability
 * behind using Context, but prefer the ProviderComposition for Context mocking.
 */
export const withMockComponent = <P extends PropsWithChildren, T>({
  Context,
  WrappedComponent,
  useMock = process.env.NODE_ENV === 'test',
  contextValue,
  router,
  testConfig,
}: {
  Context?: Context<T>
  WrappedComponent?: ComponentType<P>
  useMock?: boolean
  contextValue?: T
  router?: typeof testRouterType | typeof browserRouterType
  testConfig?: Record<string, unknown>
} = {}) => {
  return (props: P): JSX.Element => {
    if (useMock && Context && contextValue) {
      return (
        <Context.Provider value={contextValue}>
          {WrappedComponent ? (
            <WrappedComponent {...props} {...testConfig} router={router}>
              {props.children}
            </WrappedComponent>
          ) : (
            props.children
          )}
        </Context.Provider>
      )
    }

    return WrappedComponent ? (
      <WrappedComponent {...props} {...testConfig} router={router}>
        {props.children}
      </WrappedComponent>
    ) : (
      <>{props.children}</>
    )
  }
}

/**
 * Create all applicable RouterProvider providers
 *
 * @param options - AppRenderOptions of configuration for mocking, etc
 * @param router - router for NotistackSnackbarKeyContext
 * @returns - all the providers wrapped up like Christmas in a single component with children
 */
export const createRouterProviders = (
  options: AppRenderOptions = {},
  router?: Router
): React.FC<PropsWithChildren> => {
  const composition = new ProviderComposition()

  type NotistackSnackbarKeyContextType = React.ContextType<
    typeof NotistackSnackbarKeyContext
  >
  type NotistackSnackbarKeyProviderProps = React.ComponentProps<
    typeof NotistackSnackbarKeyProvider
  >

  composition.addProvider<
    NotistackSnackbarKeyContextType,
    NotistackSnackbarKeyProviderProps
  >({
    key: 'notistack',
    config: {
      Provider: NotistackSnackbarKeyProvider,
      Context: NotistackSnackbarKeyContext,
      mockValue: {
        ...options.notistackSnackbarKeyConfig,
      } as NotistackSnackbarKeyContextType,
      router,
      shouldMock: options.shouldMockNotistackSnackbarKey,
      testConfig: {
        ...options.notistackSnackbarKeyConfig,
      } as NotistackSnackbarKeyProviderProps,
    },
    dependencies: [],
  })
  type SnackbarContextType = React.ContextType<typeof SnackbarContext>
  type SnackbarProviderProps = React.ComponentProps<typeof SnackbarProvider>

  composition.addProvider<SnackbarContextType, SnackbarProviderProps>({
    key: 'snackbar',
    config: {
      Provider: SnackbarProvider,
      Context: SnackbarContext,
      mockValue: { ...options.snackbarConfig } as SnackbarContextType,
      testConfig: { ...options.snackbarConfig } as SnackbarProviderProps,
    },
    dependencies: ['notistack'],
  })

  type LearningContextType = React.ContextType<typeof LearningCenterContext>
  type LearningProviderProps = React.ComponentProps<
    typeof LearningCenterProvider
  >

  composition.addProvider<LearningContextType, LearningProviderProps>({
    key: 'learning',
    config: {
      Provider: LearningCenterProvider,
      Context: LearningCenterContext,
      mockValue: { ...options.learningConfig } as LearningContextType,
      testConfig: { ...options.learningConfig } as LearningProviderProps,
    },
    dependencies: ['snackbar'],
  })

  type BusinessContextType = React.ContextType<typeof BusinessContext>
  type BusinessProviderProps = React.ComponentProps<typeof BusinessProvider>

  composition.addProvider<BusinessContextType, BusinessProviderProps>({
    key: 'business',
    config: {
      Provider: BusinessProvider,
      Context: BusinessContext,
      mockValue: { ...options.businessConfig } as BusinessContextType,
      testConfig: { ...options.businessConfig } as BusinessProviderProps,
    },
    dependencies: ['learning'],
  })

  type CommunitiesContextType = React.ContextType<typeof CommunitiesContext>
  type CommunitiesProviderProps = React.ComponentProps<
    typeof CommunitiesProvider
  >

  composition.addProvider<CommunitiesContextType, CommunitiesProviderProps>({
    key: 'communities',
    config: {
      Provider: CommunitiesProvider,
      Context: CommunitiesContext,
      mockValue: { ...options.communitiesConfig } as CommunitiesContextType,
      testConfig: { ...options.communitiesConfig } as CommunitiesProviderProps,
    },
    dependencies: ['business'],
  })

  type FilterContextType = React.ContextType<typeof FilterContext>
  type FilterProviderProps = React.ComponentProps<typeof FilterProvider>

  composition.addProvider<FilterContextType, FilterProviderProps>({
    key: 'filter',
    config: {
      Provider: FilterProvider,
      Context: FilterContext,
      mockValue: { ...options.filterConfig } as FilterContextType,
      shouldMock: options.shouldMockFilter,
      testConfig: { ...options.filterConfig } as FilterProviderProps,
    },
    dependencies: ['communities'],
  })

  type AuthContextType = React.ContextType<typeof AuthContext>
  type AuthProviderProps = React.ComponentProps<typeof AuthProvider>

  composition.addProvider<AuthContextType, AuthProviderProps>({
    key: 'auth',
    config: {
      Provider: AuthProvider,
      Context: AuthContext,
      mockValue: { ...options.auth } as AuthContextType,
      testConfig: { ...options.auth } as AuthProviderProps,
    },
    dependencies: ['filter'],
  })
  return composition.createComposedProvider()
}

/**
 * Create all applicable PrivateRouter providers
 *
 * @param options - AppRenderOptions of configuration for mocking, etc
 * @returns - a set of the providers wrapped up like Christmas in a single components with children
 */
export const createPrivateProviders = (
  options: AppRenderOptions = {}
): {
  ScrollProvider: React.FC<PropsWithChildren>
  LoadingProvider: React.FC<PropsWithChildren>
  Providers: React.FC<PropsWithChildren>
} => {
  const scrollContextComp = new ProviderComposition()
  const loadingContextComp = new ProviderComposition()
  const composition = new ProviderComposition()

  type ScrollContextType = React.ContextType<typeof ScrollContext>
  type ScrollProviderProps = React.ComponentProps<typeof ScrollContextProvider>

  scrollContextComp.addProvider<ScrollContextType, ScrollProviderProps>({
    key: 'scroll',
    config: {
      Provider: ScrollContextProvider,
      Context: ScrollContext,
      mockValue: {} as ScrollContextType,
    },
    dependencies: [],
  })

  type LoadingContextType = React.ContextType<typeof LoadingContext>
  type LoadingProviderProps = React.ComponentProps<typeof LoadingProvider>

  loadingContextComp.addProvider<LoadingContextType, LoadingProviderProps>({
    key: 'loading',
    config: {
      Provider: LoadingProvider,
      Context: LoadingContext,
      shouldMock: options.shouldMockLoading,
      mockValue: { ...options.loadingProviderConfig } as LoadingContextType,
      testConfig: options.shouldMockLoading
        ? ({ ...options.loadingProviderConfig } as LoadingProviderProps)
        : undefined,
    },
    dependencies: [],
  })

  type CountryContextType = React.ContextType<typeof CountryContext>
  type CountryProviderProps = React.ComponentProps<typeof CountryProvider>

  composition.addProvider<CountryContextType, CountryProviderProps>({
    key: 'country',
    config: {
      Provider: CountryProvider,
      Context: CountryContext,
      mockValue: { ...options.countryConfig } as CountryContextType,
      testConfig: {
        ...options.countryConfig,
      } as CountryProviderProps,
    },
    dependencies: [],
  })

  type UserContextType = React.ContextType<typeof UserContext>
  type UserProviderProps = React.ComponentProps<typeof UserProvider>

  composition.addProvider<UserContextType, UserProviderProps>({
    key: 'user',
    config: {
      Provider: UserProvider,
      Context: UserContext,
      mockValue: {
        ...options.userConfig,
      } as UserContextType,
      testConfig: {
        ...options.userConfig,
      } as UserProviderProps,
    },
    dependencies: ['country'],
  })

  type TitleContextType = React.ContextType<typeof TitleContext>
  type TitleProviderProps = React.ComponentProps<typeof TitleProvider>

  composition.addProvider<TitleContextType, TitleProviderProps>({
    key: 'title',
    config: {
      Provider: TitleProvider,
      Context: TitleContext,
      mockValue: { ...options.titleConfig } as TitleContextType,
      testConfig: { ...options.titleConfig } as TitleProviderProps,
    },
    dependencies: ['user'],
  })

  type AccountContextType = React.ContextType<typeof AccountContext>
  type AccountProviderProps = React.ComponentProps<typeof AccountProvider>

  composition.addProvider<AccountContextType, AccountProviderProps>({
    key: 'account',
    config: {
      Provider: AccountProvider,
      Context: AccountContext,
      mockValue: { ...options.accountConfig } as AccountContextType,
      testConfig: {
        ...options.accountConfig,
      } as AccountProviderProps,
    },
    dependencies: ['title'],
  })

  type ProgramDetailsContextType = React.ContextType<
    typeof ProgramDetailsContext
  >
  type ProgramDetailsProviderProps = React.ComponentProps<
    typeof ProgramDetailsProvider
  >

  composition.addProvider<
    ProgramDetailsContextType,
    ProgramDetailsProviderProps
  >({
    key: 'program',
    config: {
      Provider: ProgramDetailsProvider,
      Context: ProgramDetailsContext,
      mockValue: { ...options.programConfig } as ProgramDetailsContextType,
      testConfig: {
        ...options.programConfig,
      } as ProgramDetailsProviderProps,
    },
    dependencies: ['account'],
  })

  type SpeedDialContextType = React.ContextType<typeof SpeedDialMenuContext>
  type SpeedDialProviderProps = React.ComponentProps<
    typeof SpeedDialMenuProvider
  >

  composition.addProvider<SpeedDialContextType, SpeedDialProviderProps>({
    key: 'speedDial',
    config: {
      Provider: SpeedDialMenuProvider,
      Context: SpeedDialMenuContext,
      mockValue: { ...options.speedDialMenuConfig } as SpeedDialContextType,
      shouldMock: options.shouldMockSpeedDial,
      testConfig: { ...options.speedDialMenuConfig } as SpeedDialProviderProps,
    },
    dependencies: ['program'],
  })

  type TranscriptContextType = React.ContextType<typeof TranscriptContext>
  type TranscriptProviderProps = React.ComponentProps<typeof TranscriptProvider>

  composition.addProvider<TranscriptContextType, TranscriptProviderProps>({
    key: 'transcript',
    config: {
      Provider: TranscriptProvider,
      Context: TranscriptContext,
      mockValue: {
        ...options.transcriptProviderConfig,
      } as TranscriptContextType,
      testConfig: {
        ...options.transcriptProviderConfig,
      } as TranscriptProviderProps,
    },
    dependencies: ['speedDial'],
  })

  type EventContextType = React.ContextType<typeof EventContext>
  type EventProviderProps = React.ComponentProps<typeof EventProvider>

  composition.addProvider<EventContextType, EventProviderProps>({
    key: 'event',
    config: {
      Provider: EventProvider,
      Context: EventContext,
      mockValue: { ...options.eventProviderConfig } as EventContextType,
      testConfig: {
        ...options.eventProviderConfig,
      } as EventProviderProps,
    },
    dependencies: ['transcript'],
  })

  type DashboardContextType = React.ContextType<typeof DashboardContext>
  type DashboardProviderProps = React.ComponentProps<typeof DashboardProvider>

  composition.addProvider<DashboardContextType, DashboardProviderProps>({
    key: 'dashboard',
    config: {
      Provider: DashboardProvider,
      Context: DashboardContext,
      mockValue: { ...options.dashboardProviderConfig } as DashboardContextType,
      testConfig: {
        ...options.dashboardProviderConfig,
      } as DashboardProviderProps,
    },
    dependencies: ['event'],
  })

  return {
    ScrollProvider: scrollContextComp.createComposedProvider(),
    LoadingProvider: loadingContextComp.createComposedProvider(),
    Providers: composition.createComposedProvider(),
  }
}

/**
 * Create all applicable PublicProvider providers
 *
 * @param options - AppRenderOptions of configuration for mocking, etc
 * @returns - all the providers wrapped up like Christmas in a single component with children
 */
export const createPublicProviders = (
  options: AppRenderOptions = {}
): React.FC<PropsWithChildren> => {
  const composition = new ProviderComposition()

  type LoadingContextType = React.ContextType<typeof LoadingContext>
  type LoadingProviderProps = React.ComponentProps<typeof LoadingProvider>

  composition.addProvider<LoadingContextType, LoadingProviderProps>({
    key: 'loading',
    config: {
      Provider: LoadingProvider,
      Context: LoadingContext,
      mockValue: {
        ...options.loadingProviderConfig,
      } as LoadingContextType,
      testConfig: {
        ...options.loadingProviderConfig,
      } as LoadingProviderProps,
    },
    dependencies: [],
  })

  type InviteContextType = React.ContextType<typeof InviteContext>
  type InviteProviderProps = React.ComponentProps<typeof InviteProvider>

  composition.addProvider<InviteContextType, InviteProviderProps>({
    key: 'invite',
    config: {
      Provider: InviteProvider,
      Context: InviteContext,
      // NO current testing value exists for the invite context, but it can be with the below:
      // mockValue: options.inviteConfig as InviteContextType,
      // testConfig: { ...options.inviteConfig } as InviteProviderProps,
    },
    dependencies: ['loading'],
  })

  type CountryContextType = React.ContextType<typeof CountryContext>
  type CountryProviderProps = React.ComponentProps<typeof CountryProvider>

  composition.addProvider<CountryContextType, CountryProviderProps>({
    key: 'country',
    config: {
      Provider: CountryProvider,
      Context: CountryContext,
      mockValue: { ...options.countryConfig } as CountryContextType,
      testConfig: { ...options.countryConfig } as CountryProviderProps,
    },
    dependencies: ['invite'],
  })

  return composition.createComposedProvider()
}
