/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CreateStudent,
  CreateStudentToJSON,
  EnrollmentInvite,
  EnrollmentInviteCreate,
  EnrollmentInviteCreateToJSON,
  EnrollmentInviteFromJSON,
  Families,
  FamiliesFromJSON,
  FetchFamilyTuitionBillingResponse,
  FetchFamilyTuitionBillingResponseFromJSON,
  InviteFamilyCommunities,
  InviteFamilyCommunitiesFromJSON,
  ListStudents,
  ListStudentsFromJSON,
  ResendEnrollmentInviteRequestBody,
  ResendEnrollmentInviteRequestBodyToJSON,
  Students,
  StudentsFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CancelInvitationRequest extends runtime.BaseRequestParameters {
  uuid: string
}

export interface CreateStudentRequest extends runtime.BaseRequestParameters {
  body?: CreateStudent
}

export interface FetchAllFamiliesRequest extends runtime.BaseRequestParameters {
  programKey?: number
  year?: number
  communityKey?: number
}

export interface FetchFamilyTuitionBillingRequest
  extends runtime.BaseRequestParameters {
  parentUserKey: number
}

export interface FetchStudentsForFamilyRequest
  extends runtime.BaseRequestParameters {
  userId: string
}

export interface InviteFamilyRequest extends runtime.BaseRequestParameters {
  body?: EnrollmentInviteCreate
}

export interface ResendInvitationRequest extends runtime.BaseRequestParameters {
  enrollmentInviteUuid: string
  body?: ResendEnrollmentInviteRequestBody
}

/**
 *
 */
export class FamiliesApi extends runtime.BaseAPI {
  /**
   * User must have a role with the Family invite grant to cancel a family enrollment invitation; otherwise a 403 error will occur.
   * cancel an enrollment invitation.
   */
  async cancelInvitationRaw(
    requestParameters: CancelInvitationRequest
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.uuid === null ||
      requestParameters.uuid === undefined
    ) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter requestParameters.uuid was null or undefined when calling cancelInvitation.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/invite/{uuid}`.replace(
        `{${'uuid'}}`,
        encodeURIComponent(String(requestParameters.uuid))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * User must have a role with the Family invite grant to cancel a family enrollment invitation; otherwise a 403 error will occur.
   * cancel an enrollment invitation.
   */
  async cancelInvitation(
    requestParameters: CancelInvitationRequest
  ): Promise<string> {
    const response = await this.cancelInvitationRaw(requestParameters)
    return await response.value()
  }

  /**
   * Creates one or more students
   * Create new student(s).
   */
  async createStudentRaw(
    requestParameters: CreateStudentRequest
  ): Promise<runtime.ApiResponse<ListStudents>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/student`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateStudentToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListStudentsFromJSON(jsonValue)
    )
  }

  /**
   * Creates one or more students
   * Create new student(s).
   */
  async createStudent(
    requestParameters: CreateStudentRequest
  ): Promise<ListStudents> {
    const response = await this.createStudentRaw(requestParameters)
    return await response.value()
  }

  /**
   * List all families the user can see, along with the the programs that the family has enrolled students in. A \"family\" is considered a user who has at least one student actively enrolled in at least one active program. User must be logged in to access this endpoint. Users will always retrieve the families that have enrolled students in the same programs that the user has enrolled students, regardless of permission. If the user has view/any/User AND view/any/Program grants, they will see all families everywhere. Otherwise, if they have both a view/Program AND a view/User grant, they will see families in their team/programs. These families can also be filtered down to within a specific community, in a specific program, or during a specific academic year via query params.
   * List of all families the logged in user can see.
   */
  async fetchAllFamiliesRaw(
    requestParameters: FetchAllFamiliesRequest
  ): Promise<runtime.ApiResponse<Families>> {
    const queryParameters: any = {}

    if (requestParameters.programKey !== undefined) {
      queryParameters['programKey'] = requestParameters.programKey
    }

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year
    }

    if (requestParameters.communityKey !== undefined) {
      queryParameters['communityKey'] = requestParameters.communityKey
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FamiliesFromJSON(jsonValue)
    )
  }

  /**
   * List all families the user can see, along with the the programs that the family has enrolled students in. A \"family\" is considered a user who has at least one student actively enrolled in at least one active program. User must be logged in to access this endpoint. Users will always retrieve the families that have enrolled students in the same programs that the user has enrolled students, regardless of permission. If the user has view/any/User AND view/any/Program grants, they will see all families everywhere. Otherwise, if they have both a view/Program AND a view/User grant, they will see families in their team/programs. These families can also be filtered down to within a specific community, in a specific program, or during a specific academic year via query params.
   * List of all families the logged in user can see.
   */
  async fetchAllFamilies(
    requestParameters: FetchAllFamiliesRequest
  ): Promise<Families> {
    const response = await this.fetchAllFamiliesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns a list of the family\'s tuition invoices and payments based on the requester being a director, tutor, or a supervisor having a director(s)/tutor(s) in their downline for the family\'s enrolled students.  ## Permissions: - A user with User/view[any] and Program/view[any] can view all of the family\'s tuition billing details. - A user with User/view[any] and Program/view[their team] can view the billing details of the programs where the family\'s students are enrolled and where someone directs/tutors those programs from the user\'s downline team. - A user with User/view[their team] and and Program/view[any] can view the billing details of the programs where the family\'s students are enrolled and where someone directs/tutors those programs from the user\'s downline team. - A user with User/view[their team] and Program/view[their team] can view the family\'s tuition billing details for the family\'s programs where the requester directs/tutors or someone in their downline directs/tutors.
   * List a family\'s tuition invoices and payments based on the requester\'s relation to the family as either a director, tutor, or supervisor (with downline directors/tutors).
   */
  async fetchFamilyTuitionBillingRaw(
    requestParameters: FetchFamilyTuitionBillingRequest
  ): Promise<runtime.ApiResponse<FetchFamilyTuitionBillingResponse>> {
    if (
      requestParameters.parentUserKey === null ||
      requestParameters.parentUserKey === undefined
    ) {
      throw new runtime.RequiredError(
        'parentUserKey',
        'Required parameter requestParameters.parentUserKey was null or undefined when calling fetchFamilyTuitionBilling.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/{parentUserKey}/tuitionBilling`.replace(
        `{${'parentUserKey'}}`,
        encodeURIComponent(String(requestParameters.parentUserKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchFamilyTuitionBillingResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns a list of the family\'s tuition invoices and payments based on the requester being a director, tutor, or a supervisor having a director(s)/tutor(s) in their downline for the family\'s enrolled students.  ## Permissions: - A user with User/view[any] and Program/view[any] can view all of the family\'s tuition billing details. - A user with User/view[any] and Program/view[their team] can view the billing details of the programs where the family\'s students are enrolled and where someone directs/tutors those programs from the user\'s downline team. - A user with User/view[their team] and and Program/view[any] can view the billing details of the programs where the family\'s students are enrolled and where someone directs/tutors those programs from the user\'s downline team. - A user with User/view[their team] and Program/view[their team] can view the family\'s tuition billing details for the family\'s programs where the requester directs/tutors or someone in their downline directs/tutors.
   * List a family\'s tuition invoices and payments based on the requester\'s relation to the family as either a director, tutor, or supervisor (with downline directors/tutors).
   */
  async fetchFamilyTuitionBilling(
    requestParameters: FetchFamilyTuitionBillingRequest
  ): Promise<FetchFamilyTuitionBillingResponse> {
    const response = await this.fetchFamilyTuitionBillingRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch Communities for an invite Family Form
   */
  async fetchInviteFamilyCommunitiesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<InviteFamilyCommunities>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/invite/communities`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InviteFamilyCommunitiesFromJSON(jsonValue)
    )
  }

  /**
   * Fetch Communities for an invite Family Form
   */
  async fetchInviteFamilyCommunities(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<InviteFamilyCommunities> {
    const response = await this.fetchInviteFamilyCommunitiesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   *  Lists students that are viewable by the requester by virtue of programs the requester can see that the students are enrolled in and the family parent\'s userKey. The visible programs the students are enrolled in are also returned with each student. It will return a 404 if the user is not found.  ## PERMISSIONS: - When the requester has no permissions, they will only be able to view their own children. - **Program/view[their team\'s]** grant allows the requester to view the students enrolled in a program where the requester or a member of the requester\'s downline team actively directs or tutors the program. - **Program/view[any]** grant allows the requester to see the students of any program, regardless of their team hierarchy.
   * List of students in a family the requester can see
   */
  async fetchStudentsForFamilyRaw(
    requestParameters: FetchStudentsForFamilyRequest
  ): Promise<runtime.ApiResponse<Students>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchStudentsForFamily.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/students`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentsFromJSON(jsonValue)
    )
  }

  /**
   *  Lists students that are viewable by the requester by virtue of programs the requester can see that the students are enrolled in and the family parent\'s userKey. The visible programs the students are enrolled in are also returned with each student. It will return a 404 if the user is not found.  ## PERMISSIONS: - When the requester has no permissions, they will only be able to view their own children. - **Program/view[their team\'s]** grant allows the requester to view the students enrolled in a program where the requester or a member of the requester\'s downline team actively directs or tutors the program. - **Program/view[any]** grant allows the requester to see the students of any program, regardless of their team hierarchy.
   * List of students in a family the requester can see
   */
  async fetchStudentsForFamily(
    requestParameters: FetchStudentsForFamilyRequest
  ): Promise<Students> {
    const response = await this.fetchStudentsForFamilyRaw(requestParameters)
    return await response.value()
  }

  /**
   * User must have a role with the Family invite grant and be the director of the specified programs to send out a family enrollment invitation; otherwise a 403 error will occur. User must also have a valid view/Program grant in order to invite to the specified programs; otherwise a 403 error will occur. Specified programs must also have enough enrollment spots left in its capacity of confirmed enrolled students, or a 400 error will occur. If a parentUserKey is specified, the inviting user must also have a view/User grant and be able to see the parentUser; otherwise a 403 error will occur.
   * Create an enrollment invitation and send the generated unique invitation link via email for a new or existing family.
   */
  async inviteFamilyRaw(
    requestParameters: InviteFamilyRequest
  ): Promise<runtime.ApiResponse<EnrollmentInvite>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/invite`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EnrollmentInviteCreateToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnrollmentInviteFromJSON(jsonValue)
    )
  }

  /**
   * User must have a role with the Family invite grant and be the director of the specified programs to send out a family enrollment invitation; otherwise a 403 error will occur. User must also have a valid view/Program grant in order to invite to the specified programs; otherwise a 403 error will occur. Specified programs must also have enough enrollment spots left in its capacity of confirmed enrolled students, or a 400 error will occur. If a parentUserKey is specified, the inviting user must also have a view/User grant and be able to see the parentUser; otherwise a 403 error will occur.
   * Create an enrollment invitation and send the generated unique invitation link via email for a new or existing family.
   */
  async inviteFamily(
    requestParameters: InviteFamilyRequest
  ): Promise<EnrollmentInvite> {
    const response = await this.inviteFamilyRaw(requestParameters)
    return await response.value()
  }

  /**
   * User must have a role with the Family invite grant and be the director of the specified programs to send out a family enrollment invitation; otherwise a 403 error will occur.
   * Resend an existing enrollment invitation to a family
   */
  async resendInvitationRaw(
    requestParameters: ResendInvitationRequest
  ): Promise<runtime.ApiResponse<EnrollmentInvite>> {
    if (
      requestParameters.enrollmentInviteUuid === null ||
      requestParameters.enrollmentInviteUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'enrollmentInviteUuid',
        'Required parameter requestParameters.enrollmentInviteUuid was null or undefined when calling resendInvitation.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/families/invite/{enrollmentInviteUuid}/resend`.replace(
        `{${'enrollmentInviteUuid'}}`,
        encodeURIComponent(String(requestParameters.enrollmentInviteUuid))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ResendEnrollmentInviteRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnrollmentInviteFromJSON(jsonValue)
    )
  }

  /**
   * User must have a role with the Family invite grant and be the director of the specified programs to send out a family enrollment invitation; otherwise a 403 error will occur.
   * Resend an existing enrollment invitation to a family
   */
  async resendInvitation(
    requestParameters: ResendInvitationRequest
  ): Promise<EnrollmentInvite> {
    const response = await this.resendInvitationRaw(requestParameters)
    return await response.value()
  }
}
