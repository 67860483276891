/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Student, StudentFromJSON, StudentToJSON } from './'

/**
 * List of all students for a family with the programs they have been associated to.
 * @export
 * @interface Students
 */
export interface Students {
  /**
   *
   * @type {Array<Student>}
   * @memberof Students
   */
  students: Array<Student>
}

export function StudentsFromJSON(json: any): Students {
  return StudentsFromJSONTyped(json, false)
}

export function StudentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Students {
  if (json === undefined || json === null) {
    return json
  }
  return {
    students: (json['students'] as Array<any>).map(StudentFromJSON),
  }
}

export function StudentsToJSON(value?: Students | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    students: (value.students as Array<any>).map(StudentToJSON),
  }
}
