/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The different styling meanings
 * @export
 * @enum {string}
 */
export enum DashboardStyleEnum {
  Normal = 'normal',
  Warning = 'warning',
  Late = 'late',
}

export function DashboardStyleEnumFromJSON(json: any): DashboardStyleEnum {
  return DashboardStyleEnumFromJSONTyped(json, false)
}

export function DashboardStyleEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DashboardStyleEnum {
  return json as DashboardStyleEnum
}

export function DashboardStyleEnumToJSON(
  value?: DashboardStyleEnum | null
): any {
  return value as any
}
