/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PaymentStartRequestBodyForEnrollments
 */
export interface PaymentStartRequestBodyForEnrollments {
  /**
   *
   * @type {number}
   * @memberof PaymentStartRequestBodyForEnrollments
   */
  programKey: number
  /**
   *
   * @type {number}
   * @memberof PaymentStartRequestBodyForEnrollments
   */
  studentKey: number
}

export function PaymentStartRequestBodyForEnrollmentsFromJSON(
  json: any
): PaymentStartRequestBodyForEnrollments {
  return PaymentStartRequestBodyForEnrollmentsFromJSONTyped(json, false)
}

export function PaymentStartRequestBodyForEnrollmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentStartRequestBodyForEnrollments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    studentKey: json['studentKey'],
  }
}

export function PaymentStartRequestBodyForEnrollmentsToJSON(
  value?: PaymentStartRequestBodyForEnrollments | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    studentKey: value.studentKey,
  }
}
