/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Address, AddressFromJSON, AddressToJSON } from './'

/**
 *
 * @export
 * @interface ProgramCommunityOption
 */
export interface ProgramCommunityOption {
  /**
   * Unique identifier for this community
   * @type {number}
   * @memberof ProgramCommunityOption
   */
  communityKey: number
  /**
   * The name of this community
   * @type {string}
   * @memberof ProgramCommunityOption
   */
  name: string
  /**
   *
   * @type {Address}
   * @memberof ProgramCommunityOption
   */
  address: Address
  /**
   * The default starting date for the communities programs' first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramCommunityOption
   */
  defaultSemesterOneStartDate: Date
  /**
   * The default starting date for the communities programs' second semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramCommunityOption
   */
  defaultSemesterTwoStartDate: Date
}

export function ProgramCommunityOptionFromJSON(
  json: any
): ProgramCommunityOption {
  return ProgramCommunityOptionFromJSONTyped(json, false)
}

export function ProgramCommunityOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramCommunityOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: json['communityKey'],
    name: json['name'],
    address: AddressFromJSON(json['address']),
    defaultSemesterOneStartDate: new Date(json['defaultSemesterOneStartDate']),
    defaultSemesterTwoStartDate: new Date(json['defaultSemesterTwoStartDate']),
  }
}

export function ProgramCommunityOptionToJSON(
  value?: ProgramCommunityOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    name: value.name,
    address: AddressToJSON(value.address),
    defaultSemesterOneStartDate: value.defaultSemesterOneStartDate
      .toISOString()
      .substr(0, 10),
    defaultSemesterTwoStartDate: value.defaultSemesterTwoStartDate
      .toISOString()
      .substr(0, 10),
  }
}
