/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementWebhookRequestBodyAgreement,
  AgreementWebhookRequestBodyAgreementFromJSON,
  AgreementWebhookRequestBodyAgreementToJSON,
  AgreementWebhookRequestBodyWebhookUrlInfo,
  AgreementWebhookRequestBodyWebhookUrlInfoFromJSON,
  AgreementWebhookRequestBodyWebhookUrlInfoToJSON,
  WebhookNotificationApplicableUsers,
  WebhookNotificationApplicableUsersFromJSON,
  WebhookNotificationApplicableUsersToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementWebhookRequestBody
 */
export interface AgreementWebhookRequestBody {
  /**
   *  The created webhook id
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  webhookId?: string
  /**
   *  The webhook name
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  webhookName?: string
  /**
   *  The created webhook id
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  webhookNotificationId?: string
  /**
   *
   * @type {AgreementWebhookRequestBodyWebhookUrlInfo}
   * @memberof AgreementWebhookRequestBody
   */
  webhookUrlInfo?: AgreementWebhookRequestBodyWebhookUrlInfo
  /**
   *  Information pending
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  webhookScope?: string
  /**
   *
   * @type {Array<WebhookNotificationApplicableUsers>}
   * @memberof AgreementWebhookRequestBody
   */
  webhookNotificationApplicableUsers?: Array<WebhookNotificationApplicableUsers>
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  event?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  subEvent?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  eventDate?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  eventResourceType?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  eventResourceParentType?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  eventResourceParentId?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  participantRole?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  participantUserId?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  participantUserEmail?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  actingUserId?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  actingUserEmail?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  actingUserIpAddress?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  initiatingUserId?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBody
   */
  initiatingUserEmail?: string
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreement}
   * @memberof AgreementWebhookRequestBody
   */
  agreement?: AgreementWebhookRequestBodyAgreement
}

export function AgreementWebhookRequestBodyFromJSON(
  json: any
): AgreementWebhookRequestBody {
  return AgreementWebhookRequestBodyFromJSONTyped(json, false)
}

export function AgreementWebhookRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementWebhookRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    webhookId: !exists(json, 'webhookId') ? undefined : json['webhookId'],
    webhookName: !exists(json, 'webhookName') ? undefined : json['webhookName'],
    webhookNotificationId: !exists(json, 'webhookNotificationId')
      ? undefined
      : json['webhookNotificationId'],
    webhookUrlInfo: !exists(json, 'webhookUrlInfo')
      ? undefined
      : AgreementWebhookRequestBodyWebhookUrlInfoFromJSON(
          json['webhookUrlInfo']
        ),
    webhookScope: !exists(json, 'webhookScope')
      ? undefined
      : json['webhookScope'],
    webhookNotificationApplicableUsers: !exists(
      json,
      'webhookNotificationApplicableUsers'
    )
      ? undefined
      : (json['webhookNotificationApplicableUsers'] as Array<any>).map(
          WebhookNotificationApplicableUsersFromJSON
        ),
    event: !exists(json, 'event') ? undefined : json['event'],
    subEvent: !exists(json, 'subEvent') ? undefined : json['subEvent'],
    eventDate: !exists(json, 'eventDate') ? undefined : json['eventDate'],
    eventResourceType: !exists(json, 'eventResourceType')
      ? undefined
      : json['eventResourceType'],
    eventResourceParentType: !exists(json, 'eventResourceParentType')
      ? undefined
      : json['eventResourceParentType'],
    eventResourceParentId: !exists(json, 'eventResourceParentId')
      ? undefined
      : json['eventResourceParentId'],
    participantRole: !exists(json, 'participantRole')
      ? undefined
      : json['participantRole'],
    participantUserId: !exists(json, 'participantUserId')
      ? undefined
      : json['participantUserId'],
    participantUserEmail: !exists(json, 'participantUserEmail')
      ? undefined
      : json['participantUserEmail'],
    actingUserId: !exists(json, 'actingUserId')
      ? undefined
      : json['actingUserId'],
    actingUserEmail: !exists(json, 'actingUserEmail')
      ? undefined
      : json['actingUserEmail'],
    actingUserIpAddress: !exists(json, 'actingUserIpAddress')
      ? undefined
      : json['actingUserIpAddress'],
    initiatingUserId: !exists(json, 'initiatingUserId')
      ? undefined
      : json['initiatingUserId'],
    initiatingUserEmail: !exists(json, 'initiatingUserEmail')
      ? undefined
      : json['initiatingUserEmail'],
    agreement: !exists(json, 'agreement')
      ? undefined
      : AgreementWebhookRequestBodyAgreementFromJSON(json['agreement']),
  }
}

export function AgreementWebhookRequestBodyToJSON(
  value?: AgreementWebhookRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    webhookId: value.webhookId,
    webhookName: value.webhookName,
    webhookNotificationId: value.webhookNotificationId,
    webhookUrlInfo: AgreementWebhookRequestBodyWebhookUrlInfoToJSON(
      value.webhookUrlInfo
    ),
    webhookScope: value.webhookScope,
    webhookNotificationApplicableUsers:
      value.webhookNotificationApplicableUsers === undefined
        ? undefined
        : (value.webhookNotificationApplicableUsers as Array<any>).map(
            WebhookNotificationApplicableUsersToJSON
          ),
    event: value.event,
    subEvent: value.subEvent,
    eventDate: value.eventDate,
    eventResourceType: value.eventResourceType,
    eventResourceParentType: value.eventResourceParentType,
    eventResourceParentId: value.eventResourceParentId,
    participantRole: value.participantRole,
    participantUserId: value.participantUserId,
    participantUserEmail: value.participantUserEmail,
    actingUserId: value.actingUserId,
    actingUserEmail: value.actingUserEmail,
    actingUserIpAddress: value.actingUserIpAddress,
    initiatingUserId: value.initiatingUserId,
    initiatingUserEmail: value.initiatingUserEmail,
    agreement: AgreementWebhookRequestBodyAgreementToJSON(value.agreement),
  }
}
