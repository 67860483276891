import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SchoolInformationCard from '../Card/SchoolInformationCard'
import { Page } from '../Elements/PageMargins'
import { useSpeedDialMenuContext } from '../Context/SpeedDialMenuContext'
import { SpeedDialActions } from '../Elements/SpeedDialMenu'
import { useSpeedDialCommonActions } from '../Elements/SpeedDialActions'
import { useMountEffect } from '../../hooks/useMountEffect'
import {
  TranscriptFlowStages,
  useTranscriptContext,
} from '../Context/TranscriptContext'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import StudentInformationCard from '../Card/StudentInformationCard'
import TranscriptNameCard from '../Card/TranscriptNameCard'
import { emptyAddressInformation } from '../Address/AddressModal'
import { useNavigate } from 'react-router'
import { useUnmountEffect } from '../../hooks/useUnmountEffect'
import ClassInformation from './ClassInformation'
import {
  CreateTranscript,
  CreateTranscriptRequestBody,
  Transcript,
  TranscriptYear,
  TranscriptYearCourseWork,
  extractedErrorObject,
  transcriptsApi,
} from '../../api/swagger'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import { useTranslation } from 'react-i18next'
import useValidationMessages from '../../hooks/useValidationMessages'
import TranscriptConfirmDeleteModal from '../Modals/TranscriptConfirmDeleteModal'
import { useDeleteTranscript } from '../../hooks/useDeleteTranscript'
import { AcademicSummaryCard } from '../Card/AcademicSummaryCard'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import TranscriptPDF from './TranscriptPDF/TranscriptPDF'
import { isEmailValid } from '../../helpers/validateEmail'
import { useAuth } from '../Routes/AuthProvider'
import useEndpoint from '../../hooks/useEndpoint'
import ConfirmForParchmentModal from '../Modals/ConfirmForParchmentModal'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import { Typography } from '@mui/material'
import { TranscriptParchmentRequestStatus } from '../../swagger/models/TranscriptParchmentRequestStatus'

export enum TranscriptFormCardVariant {
  'Add' = 'add',
  'Edit' = 'edit',
}

interface TranscriptFormCardProps {
  variant: TranscriptFormCardVariant
}

enum TranscriptFormCardValidationMessages {
  Default = 'default',
  RequiredFields = 'requiredFields',
}

/**
 * A do-all form for Adding an Editing Transcripts
 *
 * @returns JSXElement with all transcript related components
 */
export const TranscriptFormCard: React.FC<TranscriptFormCardProps> = ({
  variant,
}) => {
  const isEditVariant = variant === TranscriptFormCardVariant.Edit
  const [isEditMode, setIsEditMode] = useState(!isEditVariant)
  const { updateActions, updateIsOpen } = useSpeedDialMenuContext()
  const navigate = useNavigate()
  const {
    breadcrumbs,
    updateBreadcrumbs,
    transcriptDetails,
    cancelTranscriptEdit,
    updateTranscriptDetails,
    selectedTranscriptKey,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()
  const { schoolAddress, studentAddress, transcriptName } = transcriptDetails
  const { addLoadingIds } = React.useContext(LoadingContext)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { t } = useTranslation()
  const { TranscriptFormCard: TranscriptFormCardApi } = useLoadingIds()
  const {
    createTranscript: createTranscriptLoadingId,
    updateTranscript: updateTranscriptLoadingId,
    sendTranscriptToParchment: sendTranscriptToParchmentLoadingId,
  } = TranscriptFormCardApi
  const { userDetails } = useAuth()

  const messagesMap = useValidationMessages([
    {
      field: TranscriptFormCardValidationMessages.Default,
      message: t(
        'Transcripts.TranscriptFormCard.Error.Default',
        'An unknown error occurred.'
      ),
    },
    {
      field: TranscriptFormCardValidationMessages.RequiredFields,
      message: t(
        'Transcripts.TranscriptFormCard.Error.RequiredFields',
        'Please make sure you have filled out all required fields.'
      ),
    },
  ])

  const {
    editAction,
    cancelAction,
    saveAction,
    deleteAction,
    downloadAction,
    printAction,
    sendAction,
  } = useSpeedDialCommonActions({
    editProps: {
      onClick: () => {
        setIsEditMode(true)
      },
    },
    cancelProps: {
      onClick: () => {
        if (isEditVariant) {
          setIsEditMode(false)
          cancelTranscriptEdit()
        } else {
          navigate('/transcripts/overview')
        }
      },
    },
    saveProps: {
      onClick: () => {
        if (!isEditVariant) {
          addLoadingIds([createTranscriptLoadingId])
        } else {
          addLoadingIds([updateTranscriptLoadingId])
        }
      },
    },
    deleteProps: {
      onClick: () => {
        setShowConfirmDeleteModal(true)
      },
    },
    sendProps: {
      name: 'Send To Parchment',
      onClick: () => {
        setShowConfirmParchmentModal(true)
      },
    },
  })

  const printPDFTranscript = useCallback(() => {
    navigate(
      {
        pathname: '/transcripts/view-selected',
      },
      {
        replace: true,
        state: {
          transcriptKeys: [selectedTranscriptKey],
        },
      }
    )
  }, [selectedTranscriptKey, navigate])

  const downloadPDFTranscript = useCallback(async () => {
    const blob = await pdf(
      <TranscriptPDF
        transcripts={[{ ...(transcriptDetails as Transcript) }]}
        actingAs={userDetails.actingAs}
      />
    ).toBlob()

    saveAs(blob, transcriptName)
  }, [transcriptDetails, transcriptName, userDetails.actingAs])

  useEffect(() => {
    downloadAction.current.onClick = downloadPDFTranscript
  }, [downloadAction, downloadPDFTranscript])

  useEffect(() => {
    printAction.current.onClick = printPDFTranscript
  }, [printAction, printPDFTranscript])

  const requiredFieldsAreValid = () => {
    const studentEmail = (transcriptDetails as Transcript).studentEmail ?? ''

    const studentEmailIsValidEmail =
      studentEmail === '' ? true : isEmailValid(studentEmail)

    const hasAStudent =
      !!(transcriptDetails as Transcript).student ||
      !!(transcriptDetails as CreateTranscript).studentKey

    updateFormValidity({
      ...transcriptValidity,
      transcriptName: { input: !!transcriptDetails.transcriptName },
      schoolAddress: { input: !!transcriptDetails.schoolAddress },
      studentAddress: { input: !!transcriptDetails.studentAddress },
      schoolName: { input: !!transcriptDetails.schoolName },
      studentKey: { input: hasAStudent },
      studentEmail: { input: true, email: studentEmailIsValidEmail },
      dateGraduation: { input: true },
    })
    return (
      !!transcriptDetails.transcriptName &&
      !!transcriptDetails.schoolAddress &&
      !!transcriptDetails.schoolName &&
      hasAStudent &&
      studentEmailIsValidEmail
    )
  }

  const requiredParchmentFieldsAreValid = () => {
    const studentEmail = (transcriptDetails as Transcript).studentEmail ?? ''

    const hasStudentEmail = !(studentEmail === '' || studentEmail === undefined)

    const studentEmailIsValidEmail =
      studentEmail === '' ? true : isEmailValid(studentEmail)

    const hasGraduationDate = !!transcriptDetails.dateGraduation

    const hasStudentDateOfBirth = !!transcriptDetails.studentDateOfBirth

    updateFormValidity({
      ...transcriptValidity,
      studentEmail: { input: hasStudentEmail, email: studentEmailIsValidEmail },
      dateGraduation: { input: hasGraduationDate },
      dateOfBirth: { input: hasStudentDateOfBirth },
    })

    return (
      hasStudentEmail &&
      studentEmailIsValidEmail &&
      hasGraduationDate &&
      hasStudentDateOfBirth
    )
  }

  const [newTranscriptKey, setNewTranscriptKey] = useState<number | undefined>()

  /**
   * A simple way to wait for the react lifecycle because apparently this same order in
   * the createTranscript method doesn't render the snackbar but does navigate. But also
   * having navigate/snackbar logic split here and in the method does the wrong order...
   *
   * Well it's just one of those react things which probably has a better fix if I understood
   * more how react-router-dom, react lifecycles, and jest all worked together.
   */
  useEffect(() => {
    if (newTranscriptKey) {
      navigate(`/transcripts/transcript-details/${newTranscriptKey}`)
      setSnackbarState(true)
      setSnackbarMessage(
        t(
          'Transcripts.TranscriptFormCard.Form.CreateSuccess',
          'Transcript successfully created.'
        )
      )
      setSnackbarSeverity(SnackbarSeverity.Success)
      setNewTranscriptKey(undefined)
    }
  }, [
    navigate,
    newTranscriptKey,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarState,
    t,
  ])

  const createTranscript = async () => {
    try {
      if (requiredFieldsAreValid()) {
        const createTranscriptBody = {
          ...transcriptDetails,
        } as CreateTranscript
        const body: CreateTranscriptRequestBody = {
          transcript: {
            ...createTranscriptBody,
            studentDateOfBirth: transcriptDetails.studentDateOfBirth,
          },
        }

        const { transcript } = await transcriptsApi.createTranscript({
          body,
        })
        updateTranscriptDetails({
          ...transcript,
          studentDateOfBirth: transcript.studentDateOfBirth,
        })

        setNewTranscriptKey(transcript.transcriptKey)
      } else {
        throw new Error(
          messagesMap.get(
            TranscriptFormCardValidationMessages.RequiredFields
          ) as string
        )
      }
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: messagesMap.get(
          TranscriptFormCardValidationMessages.Default
        ) as string,
      }
      setSnackbarMessage(errorObj.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    loadingId: createTranscriptLoadingId,
    asyncFunction: createTranscript,
  })

  useEndpoint({
    swaggerCall: () =>
      transcriptsApi.sendTranscriptToParchment({
        transcriptKey: transcriptDetails.transcriptKey as number,
      }),
    loadingId: sendTranscriptToParchmentLoadingId,
    successMessage: t(
      'Transcripts.TranscriptsTable.Actions.SendToParchmentSuccess',
      'Transcript has successfully been requested to send to parchment.'
    ),
    genericErrorMessage: t(
      'Transcripts.TranscriptsTable.Actions.SendToParchmentFail',
      'An unknown error occured requesting to send transcript to parchment.'
    ),
    dontCall: () => {
      if (!requiredParchmentFieldsAreValid()) {
        setSnackbarMessage(
          t(
            'Transcripts.TranscriptFormCard.Form.SendToParchmentFail',
            'Transcript is missing fields that are required for Parchment.'
          )
        )
        setSnackbarSeverity(SnackbarSeverity.Error)
        setSnackbarState(true)
        return true
      }
      return false
    },
    successCallback: () => {
      updateTranscriptDetails({
        ...transcriptDetails,
        requestStatus: TranscriptParchmentRequestStatus.Requested,
      })
    },
  })

  /**
   * Ensure body for updateTranscript includes only coursework keys for
   * each of the transcriptYearCoursework objects. We needed them prior
   * to the update to edit the coursework.
   */
  const bodyPositivity = (d: Transcript): Transcript => {
    /**
     * Force a deep copy of the details, but don't modify the original details in case of error
     *
     * Simply doing {...transcriptDetails} still shallow copies and thereby also modifies
     * transcriptDetails when details is updated in bodyPositivity. Because of this modification
     * we could not state reset on error unless we had an initial value for the transcriptDetails.
     *
     */
    let newYears = {}
    for (const { yearKey, year } of [
      { year: d.transcriptYear1, yearKey: 'transcriptYear1' },
      { year: d.transcriptYear2, yearKey: 'transcriptYear2' },
      { year: d.transcriptYear3, yearKey: 'transcriptYear3' },
      { year: d.transcriptYear4, yearKey: 'transcriptYear4' },
    ]) {
      // If no year exists, skip it. (It shouldn't but the compiler complains)
      if (!year) break
      const newCourseWork = [] as TranscriptYearCourseWork[]
      year.transcriptYearCourseWork?.forEach((coursework) => {
        if ((coursework.transcriptYearCourseWorkKey as number) < 0) {
          newCourseWork.push({
            ...coursework,
            transcriptYearCourseWorkKey: undefined,
          })
        } else {
          newCourseWork.push(coursework)
        }
      })
      newYears = {
        ...newYears,
        [yearKey]: { ...year, transcriptYearCourseWork: newCourseWork },
      } as TranscriptYear
    }
    return { ...d, ...newYears }
  }

  const updateTranscript = async () => {
    const details = { ...transcriptDetails } as Transcript
    if (!details.transcriptKey) return

    try {
      if (requiredFieldsAreValid()) {
        await transcriptsApi.updateTranscript({
          body: { transcript: { ...bodyPositivity(details) } },
        })
        setSnackbarMessage(
          t(
            'Transcripts.TranscriptFormCard.Form.UpdateSuccess',
            'Transcript successfully updated.'
          )
        )
        setSnackbarSeverity(SnackbarSeverity.Success)
        setSnackbarState(true)
        setIsEditMode(false)
      } else {
        throw new Error(
          messagesMap.get(
            TranscriptFormCardValidationMessages.RequiredFields
          ) as string
        )
      }
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Transcripts.TranscriptFormCard.Form.DefaultError',
          'An unknown error occurred.'
        ),
      }
      setSnackbarMessage(errorObj.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    loadingId: updateTranscriptLoadingId,
    asyncFunction: updateTranscript,
  })

  useMountEffect(() => {
    updateBreadcrumbs(
      variant === TranscriptFormCardVariant.Add
        ? TranscriptFlowStages.AddTranscript
        : TranscriptFlowStages.TranscriptDetails
    )
  })

  useUnmountEffect(() => {
    updateActions(undefined)
  })

  useMountEffect(() => {
    updateIsOpen(true)
  })

  const determineActions = useCallback((): SpeedDialActions[] | undefined => {
    /**
     * If we aren't a parent, we don't have any actions (right now)
     *
     * We might have printing.
     *
     * For now we return nothing to remove the Speed Dial altogether.
     *
     * If we want to utilize `Transcript/edit`, then this is where we'll determine.
     */
    if (!(userDetails.actingAs === 'parent')) return
    if (isEditVariant) {
      /**
       * Edit should allow edit
       *
       * If editing then allow cancelling, saving
       */
      if (isEditMode) {
        return [cancelAction.current, saveAction.current]
      } else {
        return [
          editAction.current,
          downloadAction.current,
          printAction.current,
          sendAction.current,
          deleteAction.current,
        ]
      }
    } else {
      /** Adding should allow saving, cancelling */
      return [cancelAction.current, saveAction.current]
    }
  }, [
    editAction,
    isEditMode,
    isEditVariant,
    cancelAction,
    saveAction,
    deleteAction,
    downloadAction,
    printAction,
    sendAction,
    userDetails.actingAs,
  ])

  useEffect(() => {
    updateActions(determineActions())
  }, [determineActions, updateActions, isEditMode])

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const [transcriptKeyToDelete, setTranscriptKeyToDelete] = useState<number>()

  const { loadingId: deleteTranscriptLoadingId } = useDeleteTranscript({
    transcriptKey: transcriptKeyToDelete,
  })

  const onConfirmDelete = () => {
    setTranscriptKeyToDelete((transcriptDetails as Transcript).transcriptKey)
    addLoadingIds([deleteTranscriptLoadingId])
  }

  const stadDress = useMemo(() => {
    return studentAddress ?? emptyAddressInformation
  }, [studentAddress])

  const [showConfirmParchmentModal, setShowConfirmParchmentModal] =
    useState(false)

  const hideConfirmParchmentModal = () => setShowConfirmParchmentModal(false)

  return (
    <Page>
      <ConfirmForParchmentModal
        isOpen={showConfirmParchmentModal}
        onConfirm={() => {
          addLoadingIds([sendTranscriptToParchmentLoadingId])
          hideConfirmParchmentModal()
        }}
        onCancel={hideConfirmParchmentModal}
      />
      <TranscriptConfirmDeleteModal
        isOpen={showConfirmDeleteModal}
        onConfirm={onConfirmDelete}
        onCancel={() => setShowConfirmDeleteModal(false)}
      />
      <SpaceBetweenSection
        left={<DynamicBreadcrumbs breadcrumbs={breadcrumbs} />}
        right={
          <Typography variant="subtitle1" fontWeight={'bold'}>
            {t(
              'Transcripts.Legend.RequiredFields',
              '* Indicates required field'
            )}
          </Typography>
        }
      />
      <TranscriptNameCard
        isFieldDisabled={!isEditMode}
        transcriptName={transcriptName}
      />
      <SchoolInformationCard
        isFieldDisabled={!isEditMode}
        address={schoolAddress ?? emptyAddressInformation}
      />
      <StudentInformationCard
        isFieldDisabled={!isEditMode}
        address={stadDress}
        isSameAddress={!!transcriptDetails.studentAddressSameAsSchool}
      />
      <AcademicSummaryCard isFieldDisabled={!isEditMode} />
      <ClassInformation isFieldDisabled={!isEditMode} />
    </Page>
  )
}
