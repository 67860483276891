/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  EnrollmentInvite,
  EnrollmentInviteFromJSON,
  EnrollmentInviteToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface EnrollmentInvitesResponse
 */
export interface EnrollmentInvitesResponse {
  /**
   *
   * @type {Array<EnrollmentInvite>}
   * @memberof EnrollmentInvitesResponse
   */
  enrollmentInvites?: Array<EnrollmentInvite>
  /**
   *
   * @type {PaginationResponse}
   * @memberof EnrollmentInvitesResponse
   */
  pagination?: PaginationResponse
}

export function EnrollmentInvitesResponseFromJSON(
  json: any
): EnrollmentInvitesResponse {
  return EnrollmentInvitesResponseFromJSONTyped(json, false)
}

export function EnrollmentInvitesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInvitesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enrollmentInvites: !exists(json, 'enrollmentInvites')
      ? undefined
      : (json['enrollmentInvites'] as Array<any>).map(EnrollmentInviteFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function EnrollmentInvitesResponseToJSON(
  value?: EnrollmentInvitesResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enrollmentInvites:
      value.enrollmentInvites === undefined
        ? undefined
        : (value.enrollmentInvites as Array<any>).map(EnrollmentInviteToJSON),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
