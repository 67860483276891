/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  DetailMeta,
  DetailMetaFromJSON,
  DetailMetaToJSON,
} from './'

/**
 *
 * @export
 * @interface CommunityDetail
 */
export interface CommunityDetail {
  /**
   * Community unique identifier
   * @type {number}
   * @memberof CommunityDetail
   */
  communityKey: number
  /**
   * Name of the community.
   * @type {string}
   * @memberof CommunityDetail
   */
  name: string
  /**
   * Name of the region where the community belongs.
   * @type {string}
   * @memberof CommunityDetail
   */
  region: string
  /**
   *
   * @type {Address}
   * @memberof CommunityDetail
   */
  address: Address
  /**
   * The actorKey of the 'Support Representative' for this particular community
   * @type {number}
   * @memberof CommunityDetail
   */
  actorKey?: number
  /**
   * The name of the 'Support Representative' for this particular community
   * @type {string}
   * @memberof CommunityDetail
   */
  supportRepresentative: string
  /**
   * The user identifier for the support representative actor.
   * @type {number}
   * @memberof CommunityDetail
   */
  supportRepresentativeUserKey?: number
  /**
   * The name of the role of the 'Support Representative' for this particular community
   * @type {string}
   * @memberof CommunityDetail
   */
  roleName?: string
  /**
   * The contractual model for a tutor assigned to the community.
   * @type {string}
   * @memberof CommunityDetail
   */
  tutorModel?: string
  /**
   * The percentage of fees that are given to the tutor based on the tutor model.
   * @type {number}
   * @memberof CommunityDetail
   */
  feePercentage?: number
  /**
   * The hideFromPublicSearch prevents a community from being displayed on the marketing site.
   * @type {boolean}
   * @memberof CommunityDetail
   */
  hideFromPublicSearch: boolean
  /**
   *
   * @type {DetailMeta}
   * @memberof CommunityDetail
   */
  meta?: DetailMeta
}

export function CommunityDetailFromJSON(json: any): CommunityDetail {
  return CommunityDetailFromJSONTyped(json, false)
}

export function CommunityDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunityDetail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: json['communityKey'],
    name: json['name'],
    region: json['region'],
    address: AddressFromJSON(json['address']),
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    supportRepresentative: json['supportRepresentative'],
    supportRepresentativeUserKey: !exists(json, 'supportRepresentativeUserKey')
      ? undefined
      : json['supportRepresentativeUserKey'],
    roleName: !exists(json, 'roleName') ? undefined : json['roleName'],
    tutorModel: !exists(json, 'tutorModel') ? undefined : json['tutorModel'],
    feePercentage: !exists(json, 'feePercentage')
      ? undefined
      : json['feePercentage'],
    hideFromPublicSearch: json['hideFromPublicSearch'],
    meta: !exists(json, 'meta') ? undefined : DetailMetaFromJSON(json['meta']),
  }
}

export function CommunityDetailToJSON(value?: CommunityDetail | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    name: value.name,
    region: value.region,
    address: AddressToJSON(value.address),
    actorKey: value.actorKey,
    supportRepresentative: value.supportRepresentative,
    supportRepresentativeUserKey: value.supportRepresentativeUserKey,
    roleName: value.roleName,
    tutorModel: value.tutorModel,
    feePercentage: value.feePercentage,
    hideFromPublicSearch: value.hideFromPublicSearch,
    meta: DetailMetaToJSON(value.meta),
  }
}
