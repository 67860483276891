import React from 'react'
import { RouterProvider, Outlet, Navigate } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import './i18n'
import Communities from './components/Communities/Communities'
import AddCommunity from './components/Communities/AddCommunity'
import AddProgram from './components/Programs/AddProgram'
import AppScaffold from './AppScaffold'
import CommunityDetails, {
  CommunityProgramLayout,
} from './components/Communities/CommunityDetails'
import ProgramDetails from './components/Programs/ProgramDetails'
import Events from './components/Events/Events'
import EventDetails from './components/Events/EventDetails'
import AddEvent from './components/Events/AddEvent'
import Account, {
  AccountSettingsTabLayout,
  BillingTabLayout,
  EnrollmentCardLayout,
  ProfileTabLayout,
} from './components/Account/Account'
import Business from './components/Business/Business'
import BusinessContent from './components/Business/BusinessContent'
import LearningPath from './components/Business/LearningPath'
import LearningCenter from './components/LearningCenter/LearningCenter'
import ContentNDA from './components/Business/ContentNDA'
import TermsOfUse from './components/TermsOfUse/TermsOfUse'
import EmptyFamilies from './components/Families/EmptyFamilies'
import FamilyProfile from './components/Families/FamilyProfile'
import InviteFamily from './components/Families/InviteFamily'
import InvoiceDetails from './components/Families/InvoiceDetails'
import Login from './components/Login/Login'
import ResetPassword from './components/Login/ResetPassword'
import TeamTab from './components/Team/TeamTab'
import AddTeamMember from './components/Team/AddTeamMember'
import UserAccounts from './components/Admin/UserAccounts/UserAccounts'
import RoleDetails from './components/Admin/Roles/RoleDetails'
import AddRole from './components/Admin/Roles/AddRole'
import AddRegion from './components/Admin/Regions/AddRegion'
import Regions from './components/Admin/Regions/Regions'
import RegionDetails from './components/Admin/Regions/RegionDetails'
import CountryInformation from './components/Card/CountryInformation'
import ErrorElement from './components/Elements/ErrorElement'
import {
  CommunityFamilyLayout,
  CommunityDetailsLayout,
} from './components/Communities/CommunityDetails'
import LearningCenterContent from './components/LearningCenter/LearningCenterContent'
import { ParentIndex } from './components/Events/ParentIndex'
import InviteForm from './components/Login/InviteForm'
import InvitationSummaryCard from './components/Card/InvitationSummaryCard'
import InvitesLayout from './components/Account/Invites/InvitesLayout'
import PendingInvitationsCard from './components/Card/PendingInvitationsCard'
import AssignChildrenCard from './components/Card/AssignChildrenCard'
import AddChildrenCard from './components/Card/AddChildrenCard'
import FullScreenContainer from './components/LearningCenter/FullScreenContainer'
import { LearningCenterTabs } from './utils/searchAndFilterEnums'
import UpdateParentInfoCard from './components/Card/UpdateParentInfoCard'
import UpdateChildrenInfoCard from './components/Card/UpdateChildrenInfoCard'
import TestRoute from './components/Elements/TestRoute'
import Transcripts from './components/Transcripts/Transcripts'
import TranscriptDetails from './components/Transcripts/TranscriptDetails'
import AddTranscript from './components/Transcripts/AddTranscript'
import { PDFTranscriptsPreview } from './components/Transcripts/PDFTranscriptsPreview'
import BillingTab from './components/Account/Billing/BillingTab'
import { LicensingBillingExpiredUserRolesTable } from './components/Account/Billing/LicensingBillingExpiredUserRolesTable'
import { BillingPDFPreview } from './components/Account/Billing/BillingPDF/BillingPDFPreview'
import LearningPathLC from './components/LearningCenter/LearningPathLC'
import {
  PrivateProviders,
  PublicProviders,
  RouterProviders,
} from './components/Context/Providers'
import DashboardSummary from './components/TeamLeaderDashboard/DashboardSummary'
import { ActionLink } from './swagger'
import DashboardDetails from './components/TeamLeaderDashboard/DashboardDetails'
import { getMetricIdForRoute } from './components/TeamLeaderDashboard/utils/getMetricIdForRoute'

export const fullScreenAssetPath =
  '/content/asset/fullScreen/:widenAssetKey/:academicYear?'

const App: React.FunctionComponent = () => {
  const privateRoutes = [
    {
      /** This initial element is a wrapper for all preceding children */
      element: (
        <>
          {/* 
            TODO: Look into Scroll Restoration 
            https://reactrouter.com/en/main/components/scroll-restoration 
          */}
          <PrivateProviders>
            <Outlet />
          </PrivateProviders>
        </>
      ),
      /**
       * !!! ATTENTION DEVELOPERS !!!
       *
       * For the ease of reading, please keep new routes in alphabetical order.
       */
      children: [
        {
          /**
           * The parent to all routes defined in `children`, wrap in AppScaffold.
           *
           * There is a separate route with /* which redirects to /account/profile
           */
          path: '/',
          element: (
            <AppScaffold>
              <Outlet />
            </AppScaffold>
          ),
          errorElement: <ErrorElement />,
          children: [
            // Account
            {
              path: '/account',
              /**
               * Account used to be a tab view which held children nodes.
               * This required use of Outlet within, and likely other parent
               * components will do the same
               */
              element: <Account />,
              errorElement: <ErrorElement />,
              children: [
                {
                  /** Children must begin with their parent path, in this case /account */
                  path: '/account/profile',
                  element: <ProfileTabLayout />,
                  errorElement: <ErrorElement />,
                  /** Loading data before render https://reactrouter.com/en/main/route/loader */
                },
                {
                  /** Children must begin with their parent path, in this case /account */
                  path: '/account/billing',
                  element: <BillingTabLayout />,
                  errorElement: <ErrorElement />,
                  children: [
                    {
                      index: true,
                      element: <BillingTab key="account-billing" />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      path: 'roles',
                      element: <LicensingBillingExpiredUserRolesTable />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      path: 'history/:actorKey',
                      element: <BillingTab />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      path: 'history/:actorKey/report',
                      element: (
                        <BillingPDFPreview key="billing-history-report" />
                      ),
                      errorElement: <ErrorElement />,
                    },
                    {
                      path: ':actorKey/report',
                      element: <BillingPDFPreview />,
                      errorElement: <ErrorElement />,
                    },
                  ],
                },
                {
                  /** New team leader dashboard that will eventually replace the old director dashboard */
                  path: '/account/dashboard',
                  element: <DashboardSummary />,
                  errorElement: <ErrorElement />,
                },
                ...Object.values(ActionLink).map((path) => ({
                  path,
                  element: (
                    <DashboardDetails
                      metricId={getMetricIdForRoute(`${path}`)}
                    />
                  ),
                  errorElement: <ErrorElement />,
                })),
                // Explicit paths with the actorKey to prevent a routing nightmare
                ...Object.values(ActionLink).map((path) => ({
                  path: `${path}/:actorKey`,
                  element: (
                    <DashboardDetails
                      metricId={getMetricIdForRoute(`${path}`)}
                    />
                  ),
                  errorElement: <ErrorElement />,
                })),
                {
                  /** Children must begin with their parent path, in this case /account */
                  path: '/account/settings',
                  element: <AccountSettingsTabLayout />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /account */
                  element: <InvitesLayout />,
                  errorElement: <ErrorElement />,
                  children: [
                    {
                      path: '/account/invites/*',
                      element: <PendingInvitationsCard />,
                    },
                    {
                      path: '/account/invites/invitation-summary',
                      element: <InvitationSummaryCard />,
                    },
                    {
                      path: '/account/invites/assign-children',
                      element: <AssignChildrenCard />,
                    },
                    {
                      path: '/account/invites/enrollment-summary',
                      element: <EnrollmentCardLayout />,
                    },
                    {
                      path: '/account/invites/add-children',
                      element: <AddChildrenCard />,
                    },
                    {
                      path: '/account/invites/update-parent-info',
                      element: <UpdateParentInfoCard />,
                    },
                    {
                      path: '/account/invites/assign-children/update-children-info',
                      element: <UpdateChildrenInfoCard />,
                    },
                  ],
                },
                {
                  /**
                   * The catch-all route to redirect to /account/profile.
                   *
                   * We can't use defaultAccountPath from AuthProvider because
                   * this isn't contained in the AuthProvider.
                   */
                  path: '/account/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: '/account/profile',
                      }}
                    />
                  ),
                },
              ],
            },
            // Invoices
            {
              path: '/invoice/:invoiceId',
              element: <InvoiceDetails />,
              errorElement: <ErrorElement />,
            },
            // Admin
            {
              path: '/admin',
              children: [
                {
                  /** Children must begin with their parent path, in this case /admin */
                  path: '/admin/user-accounts',
                  element: <UserAccounts />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /admin */
                  path: '/admin/roles',
                  element: <UserAccounts />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /admin */
                  path: '/admin/user-accounts/:userId',
                  element: <UserAccounts />,
                  errorElement: <ErrorElement />,
                },
                {
                  path: '/admin/user-accounts/:userKey/report',
                  element: <UserAccounts />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /admin */
                  path: '/admin/regions',
                  element: <Regions />,
                  errorElement: <ErrorElement />,
                  children: [
                    {
                      /** Children must begin with their parent path, in this case /admin/regions */
                      path: '/admin/regions/add-region',
                      element: <AddRegion />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /admin/regions */
                      path: '/admin/regions/region-details/:regionKey',
                      element: <RegionDetails />,
                      errorElement: <ErrorElement />,
                    },
                  ],
                },
                {
                  /** Children must begin with their parent path, in this case /admin */
                  path: '/admin/roles',
                  children: [
                    {
                      /** Children must begin with their parent path, in this case /admin/roles */
                      path: '/admin/roles/add-role',
                      element: <AddRole />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /admin/roles */
                      path: '/admin/roles/role-details/:roleKey',
                      element: <RoleDetails />,
                      errorElement: <ErrorElement />,
                    },
                  ],
                },
                {
                  /**
                   * The catch-all route to redirect to /admin/user-accounts.
                   */
                  path: '/admin/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: '/admin/user-accounts',
                      }}
                    />
                  ),
                  errorElement: <ErrorElement />,
                },
              ],
            },
            // Business
            {
              path: '/business',
              element: <Business />,
              errorElement: <ErrorElement />,
              children: [
                {
                  /** Children must begin with their parent path, in this case /business */
                  path: '/business/nda/:assetKey',
                  element: <ContentNDA />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /business */
                  path: '/business/business-content/:assetKey',
                  element: <BusinessContent />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /business */
                  path: '/business/learning-path/:assetKey',
                  element: <LearningPath />,
                  errorElement: <ErrorElement />,
                },
                {
                  /**
                   * The catch-all route to redirect to /business.
                   */
                  path: '/business/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: '/business',
                      }}
                    />
                  ),
                  errorElement: <ErrorElement />,
                },
              ],
            },
            // Communities
            {
              path: '/communities',
              element: <ParentIndex />,
              errorElement: <ErrorElement />,
              children: [
                {
                  index: true,
                  element: <Communities />,
                },
                {
                  /** Children must begin with their parent path, in this case /communities */
                  path: '/communities/add-community',
                  element: <AddCommunity />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /communities */
                  path: '/communities/community-details/:communityKey',
                  element: <CommunityDetails />,
                  errorElement: <ErrorElement />,
                  /** Programs is included within community details, since the program is contained within a community */
                  children: [
                    {
                      /** Children must begin with their parent path, in this case /communities/community-details/:communityKey */
                      path: '/communities/community-details/:communityKey/programs/program-details/:programKey',
                      element: <ProgramDetails />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /communities/community-details/:communityKey */
                      path: '/communities/community-details/:communityKey/programs/add-program',
                      element: <AddProgram />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /communities/community-details/:communityKey */
                      path: '/communities/community-details/:communityKey/overview',
                      element: <CommunityDetailsLayout />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /communities/community-details/:communityKey */
                      path: '/communities/community-details/:communityKey/programs',
                      element: <CommunityProgramLayout />,
                      errorElement: <ErrorElement />,
                    },
                    {
                      /** Children must begin with their parent path, in this case /communities/community-details/:communityKey */
                      path: '/communities/community-details/:communityKey/families',
                      element: <CommunityFamilyLayout />,
                      errorElement: <ErrorElement />,
                    },
                  ],
                },
                {
                  /**
                   * The catch-all route to redirect to /communities.
                   */
                  path: '/communities/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: '/communities',
                      }}
                    />
                  ),
                  errorElement: <ErrorElement />,
                },
              ],
            },
            // Events
            {
              path: '/events',
              element: <ParentIndex />,
              errorElement: <ErrorElement />,
              children: [
                {
                  index: true,
                  element: <Events />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /events */
                  path: '/events/add-event',
                  element: <AddEvent />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /events */
                  path: '/events/event-details/:eventKey',
                  element: <EventDetails />,
                  errorElement: <ErrorElement />,
                },
              ],
            },
            // Families @deprecated
            {
              /**
               * FIXME: This is our opportunity to reroute families under communities,
               * since that's where it resides.
               */
              path: '/families',
              element: <EmptyFamilies />,
              errorElement: <ErrorElement />,
            },
            // Family Profile
            {
              path: '/family-profile/:userId',
              element: <FamilyProfile />,
              errorElement: <ErrorElement />,
              children: [
                {
                  /** Children must begin with their parent path, in this case /family-profile/:userId */
                  path: '/family-profile/:userId/invoice/:invoiceId?',
                  element: <InvoiceDetails />,
                  errorElement: <ErrorElement />,
                },
              ],
            },
            // Invite a New Family
            {
              /**
               * FIXME: This is our opportunity to reroute families under communities,
               * since that's where it resides.
               */
              path: '/invite-new-family',
              element: <InviteFamily />,
              errorElement: <ErrorElement />,
            },
            // Invite an Existing Family
            {
              /**
               * FIXME: This is our opportunity to reroute families under communities,
               * since that's where it resides.
               */
              path: '/invite-existing-family/:userId',
              element: <InviteFamily />,
              errorElement: <ErrorElement />,
            },
            // Learning Center
            {
              path: '/learning',
              element: <LearningCenter />,
              errorElement: <ErrorElement />,
              children: [
                {
                  /** Children must begin with their parent path, in this case /learning */
                  path: '/learning/:selectedTabKey',
                  element: <LearningCenter />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /learning */
                  path: '/learning/learning-content/:assetKey',
                  element: <LearningCenterContent />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /learning */
                  path: '/learning/:programType/learning-path/:assetKey',
                  element: <LearningPathLC />,
                  errorElement: <ErrorElement />,
                },
                {
                  /**
                   * The catch-all route to redirect to a known page.
                   */
                  path: '/learning/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: `/learning/${LearningCenterTabs.WelcomeCenter.toLowerCase()}`,
                      }}
                    />
                  ),
                },
              ],
            },
            // Team https://www.youtube.com/watch?v=XRPUoz1TYro
            {
              path: '/team',
              element: <TeamTab />,
              errorElement: <ErrorElement />,
              children: [
                {
                  /** Children must begin with their parent path, in this case /team */
                  path: '/team/add-team-member',
                  element: <AddTeamMember />,
                  errorElement: <ErrorElement />,
                },
                {
                  /** Children must begin with their parent path, in this case /team */
                  path: '/team/invites',
                  element: <TeamTab />,
                  errorElement: <ErrorElement />,
                },
                {
                  /**
                   * The catch-all route to redirect to /team.
                   */
                  path: '/team/*',
                  element: (
                    <Navigate
                      to={{
                        pathname: '/team',
                      }}
                    />
                  ),
                },
              ],
            },
            // Terms & Conditions: https://www.youtube.com/watch?v=mIA-PrhUMcA
            {
              path: '/termsOfUse',
              element: <TermsOfUse />,
              errorElement: <ErrorElement />,
            },
            /**
             * A test route to view a component being built without a route.
             * (In case you don't want to wait to visualize)
             */
            {
              path: '/test-route',
              element: <TestRoute />,
              errorElement: <ErrorElement />,
            },
            // Transcripts
            {
              path: '/transcripts',
              children: [
                {
                  path: '/transcripts/overview',
                  element: <Transcripts />,
                },
                {
                  path: '/transcripts/transcript-details/:transcriptKey',
                  element: <TranscriptDetails />,
                },
                {
                  path: '/transcripts/add-transcript',
                  element: <AddTranscript />,
                },
                {
                  path: '/transcripts/view-selected',
                  element: <PDFTranscriptsPreview />,
                },
              ],
            },
            {
              /**
               * The catch-all route to redirect to /account/profile.
               */
              path: '/',
              element: (
                <Navigate
                  to={{
                    pathname: '/account',
                  }}
                />
              ),
            },
          ],
        },
        // Full Screen Assets
        {
          /**
           * We have placed this route outside of "/"
           * as we don't wish to use the AppScaffold nor learning center Layout for this specific route
           */
          path: fullScreenAssetPath,
          element: <FullScreenContainer />,
          errorElement: <ErrorElement />,
        },
      ],
    },
  ]

  const router = createBrowserRouter([
    {
      element: (
        <PublicProviders>
          <Outlet />
        </PublicProviders>
      ),
      children: [
        {
          path: '/country-information',
          element: <CountryInformation />,
          errorElement: <ErrorElement />,
        },
        {
          path: '/invite/:inviteKey',
          element: <InviteForm />,
          errorElement: <ErrorElement />,
        },
        {
          path: '/login',
          element: <Login />,
          errorElement: <ErrorElement />,
          children: [
            {
              path: '/login/reset',
              element: <ResetPassword />,
              errorElement: <ErrorElement />,
            },
          ],
        },
        {
          /**
           * The catch-all route to redirect to /login.
           */
          path: '*',
          element: (
            <Navigate
              to={{
                pathname: '/login',
              }}
              state={{ from: location.pathname }}
            />
          ),
        },
        ...privateRoutes,
      ],
    },
  ])

  return (
    /**
     * The Learning Center Context is outside of the Auth Context so we can reset
     * the values on signout and updateActingAs. Since we're not calling anything
     * within the context requiring permissions, this is OK.
     */

    <RouterProviders router={router}>
      <RouterProvider router={router} />
    </RouterProviders>
  )
}

export default App
