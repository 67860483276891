/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  FetchEventsResponse,
  FetchEventsResponseFromJSON,
  FetchEventsResponseToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface Events1
 */
export interface Events1 {
  /**
   *
   * @type {Array<FetchEventsResponse>}
   * @memberof Events1
   */
  events: Array<FetchEventsResponse>
  /**
   *
   * @type {PaginationResponse}
   * @memberof Events1
   */
  pagination?: PaginationResponse
}

export function Events1FromJSON(json: any): Events1 {
  return Events1FromJSONTyped(json, false)
}

export function Events1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Events1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    events: (json['events'] as Array<any>).map(FetchEventsResponseFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function Events1ToJSON(value?: Events1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    events: (value.events as Array<any>).map(FetchEventsResponseToJSON),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
