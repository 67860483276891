import PrintIcon from '@mui/icons-material/Print'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { LicensingBill, PaymentStatusKey } from '../../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import { IlicensingProgramOptionsWithOwedValue } from '../../Admin/UserAccounts/UserAccount'
import IconTextButton, {
  IconTextButtonVariant,
} from '../../Buttons/IconTextButton'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../Context/SnackbarContext'
import Header from '../../Elements/Header'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import { Payment } from '../../Interfaces/Payment'
import { useAuth } from '../../Routes/AuthProvider'
import BillingHistorySummaryTable, {
  BillingHistorySummaryTableVariant,
} from './BillingHistorySummaryTable'
import ProgramLicensingInformation from './ProgramLicensingInformation'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useUser } from '../../../UserContext'
export interface LicensingPaymentsCardProps {
  licensingPaymentHistory: Payment[]
  licensingBills: LicensingBill[]
  achDiscount?: number
  amountDue?: number
  userKey?: number
  variantForBillingHistorySummaryTable?: BillingHistorySummaryTableVariant
  refetch?: () => void
  hasPermissionToFetchPaymentOptions?: boolean
  licensingProgramOptions?: IlicensingProgramOptionsWithOwedValue[]
  achAllowed: boolean
}

export const ACADEMIC_YEAR_TO_DISABLE_SHOW_LICENSING_DETAILS = 2022
export const LicensingPaymentsCard: React.FC<LicensingPaymentsCardProps> = ({
  licensingPaymentHistory,
  licensingBills,
  achDiscount,
  amountDue,
  userKey,
  variantForBillingHistorySummaryTable,
  refetch,
  hasPermissionToFetchPaymentOptions,
  licensingProgramOptions,
  achAllowed,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { actorKey } = useParams()
  const location = useLocation()
  const { uniqueInactiveUserRoles } = useUser()

  const isViewingBillingHistory = !!actorKey

  const isViewingRoleBillingHistory =
    uniqueInactiveUserRoles.some(
      (userRole) => userRole.actorKey === Number(actorKey)
    ) && !!location.pathname.match(/\/history/)

  const { permissionAbility, userDetails } = useAuth()

  // used to condition print reports button render based on actor permissions
  const canViewProgramFinancialReport = permissionAbility.can(
    'canViewProgramFinancialReport',
    'Reports'
  )

  const isLicensingPaymentsCSR =
    !!variantForBillingHistorySummaryTable &&
    variantForBillingHistorySummaryTable ===
      BillingHistorySummaryTableVariant.LicensingPaymentsCSR

  const disableMakeAPayment =
    !!licensingPaymentHistory &&
    licensingPaymentHistory.some((payment) => {
      return payment.paymentStatusKey === PaymentStatusKey.Err
    })

  const cannotMakePaymentError = t(
    'Account.BillingTab.CannotMakePayments.ErrorMessage',
    'An error has been identified with your transaction. Kindly reach out to our Customer Support Representative for assistance.'
  )

  useMountEffect(() => {
    // if actorKey is not defined, then we are in the billing tab
    if (disableMakeAPayment && !isViewingBillingHistory) {
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(cannotMakePaymentError)
      setSnackbarState(true)
    }
  })

  const disableShowDetails = (licensingBill: LicensingBill) => {
    return (
      licensingBill._for.semesterOneStartDate &&
      licensingBill._for.semesterOneStartDate.getFullYear() <=
        ACADEMIC_YEAR_TO_DISABLE_SHOW_LICENSING_DETAILS
    )
  }

  const navigateToReports = () => {
    const currentActorKey = actorKey ?? userDetails.actingAs

    const navigateTo = isViewingRoleBillingHistory
      ? `/account/billing/history/${currentActorKey}/report`
      : `/account/billing/${currentActorKey}/report`

    navigate({
      pathname: navigateTo,
    })
  }

  const navigateToUserLicensingReport = () => {
    navigate({
      pathname: `/admin/user-accounts/${userKey}/report`,
    })
  }

  return (
    <Card aria-labelledby="licensingPayments" component={Paper}>
      {!isLicensingPaymentsCSR && (
        <SpaceBetweenSection
          isCentered
          marginRight={5}
          marginLeft={2}
          left={
            <Header
              id="licensingPayments"
              headerName={t(
                'LicensingPaymentsCard.Heading.LicensingPayments',
                'Licensing Payments'
              )}
              component="h6"
            />
          }
          right={
            canViewProgramFinancialReport || isViewingRoleBillingHistory ? (
              <IconTextButton
                id="printReports"
                variant={IconTextButtonVariant.LicensingFeeReport}
                startIcon={<PrintIcon />}
                onClick={navigateToReports}
              />
            ) : null
          }
        ></SpaceBetweenSection>
      )}
      {!!licensingBills &&
        licensingBills.length > 0 &&
        licensingBills.map((licensingBill, index) => {
          return (
            <Box ml={2} mr={3} mt={3} key={licensingBill._for.programKey}>
              {index === 0 &&
                location.pathname.match(/\/admin\/user-accounts/) && (
                  <SpaceBetweenSection
                    right={
                      <IconTextButton
                        id="printUserLicensingReport"
                        variant={IconTextButtonVariant.LicensingFeeReport}
                        startIcon={<PrintIcon />}
                        onClick={navigateToUserLicensingReport}
                      />
                    }
                  />
                )}
              <ProgramLicensingInformation
                header={
                  `${licensingBill._for.programType ?? ''} (${
                    licensingBill._for.semesterOneStartDate
                      ? dateToSlashStringReinterpretedAsLocal(
                          licensingBill._for.semesterOneStartDate as Date
                        )
                      : ''
                  }) - ${licensingBill._for.communityName ?? ''}` ?? ''
                }
                program={licensingBill}
                achDiscount={achDiscount ?? 0}
                refetch={refetch}
                disableMakeAPayment={disableMakeAPayment}
                shouldHideMakeAPaymentButton={isViewingRoleBillingHistory}
                disableShowDetails={disableShowDetails(licensingBill)}
                variant={variantForBillingHistorySummaryTable}
                achAllowed={achAllowed}
              />
              <Divider sx={{ borderBottomWidth: '5px' }} />
            </Box>
          )
        })}
      <BillingHistorySummaryTable
        variant={
          variantForBillingHistorySummaryTable ??
          BillingHistorySummaryTableVariant.LicensingPayments
        }
        ariaLabelledBy="licensingPaymentHistory"
        paymentHistory={licensingPaymentHistory}
        amountDue={amountDue ?? 0}
        userKey={userKey}
        triggerRefresh={refetch}
        hasPermissionToFetchPaymentOptions={hasPermissionToFetchPaymentOptions}
        licensingProgramOptions={licensingProgramOptions}
      />
    </Card>
  )
}

export default LicensingPaymentsCard
