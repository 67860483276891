/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 *
 * @export
 * @interface EnrollmentFee
 */
export interface EnrollmentFee {
  /**
   *
   * @type {number}
   * @memberof EnrollmentFee
   */
  programKey: number
  /**
   *
   * @type {number}
   * @memberof EnrollmentFee
   */
  studentKey: number
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentFee
   */
  total: CashAmount
  /**
   *
   * @type {string}
   * @memberof EnrollmentFee
   */
  status: EnrollmentFeeStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum EnrollmentFeeStatusEnum {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
}

export function EnrollmentFeeFromJSON(json: any): EnrollmentFee {
  return EnrollmentFeeFromJSONTyped(json, false)
}

export function EnrollmentFeeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentFee {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    studentKey: json['studentKey'],
    total: CashAmountFromJSON(json['total']),
    status: json['status'],
  }
}

export function EnrollmentFeeToJSON(value?: EnrollmentFee | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    studentKey: value.studentKey,
    total: CashAmountToJSON(value.total),
    status: value.status,
  }
}
