/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ActionLink,
  ActionLinkFromJSON,
  ActionLinkToJSON,
  DashboardStyleEnum,
  DashboardStyleEnumFromJSON,
  DashboardStyleEnumToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchMetricsResponseBodyMetrics
 */
export interface FetchMetricsResponseBodyMetrics {
  /**
   *
   * @type {string}
   * @memberof FetchMetricsResponseBodyMetrics
   */
  metricName: string
  /**
   *
   * @type {Array<number>}
   * @memberof FetchMetricsResponseBodyMetrics
   */
  values: Array<number>
  /**
   *
   * @type {DashboardStyleEnum}
   * @memberof FetchMetricsResponseBodyMetrics
   */
  style: DashboardStyleEnum
  /**
   *
   * @type {ActionLink}
   * @memberof FetchMetricsResponseBodyMetrics
   */
  actionLink: ActionLink
}

export function FetchMetricsResponseBodyMetricsFromJSON(
  json: any
): FetchMetricsResponseBodyMetrics {
  return FetchMetricsResponseBodyMetricsFromJSONTyped(json, false)
}

export function FetchMetricsResponseBodyMetricsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchMetricsResponseBodyMetrics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metricName: json['metricName'],
    values: json['values'],
    style: DashboardStyleEnumFromJSON(json['style']),
    actionLink: ActionLinkFromJSON(json['actionLink']),
  }
}

export function FetchMetricsResponseBodyMetricsToJSON(
  value?: FetchMetricsResponseBodyMetrics | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metricName: value.metricName,
    values: value.values,
    style: DashboardStyleEnumToJSON(value.style),
    actionLink: ActionLinkToJSON(value.actionLink),
  }
}
