/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface SupportDocuments
 */
export interface SupportDocuments {
  /**
   *
   * @type {string}
   * @memberof SupportDocuments
   */
  displayLabel?: string
  /**
   *
   * @type {string}
   * @memberof SupportDocuments
   */
  fieldName?: string
  /**
   *
   * @type {string}
   * @memberof SupportDocuments
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SupportDocuments
   */
  mimeType?: string
  /**
   *
   * @type {string}
   * @memberof SupportDocuments
   */
  numPages?: string
}

export function SupportDocumentsFromJSON(json: any): SupportDocuments {
  return SupportDocumentsFromJSONTyped(json, false)
}

export function SupportDocumentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupportDocuments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    displayLabel: !exists(json, 'displayLabel')
      ? undefined
      : json['displayLabel'],
    fieldName: !exists(json, 'fieldName') ? undefined : json['fieldName'],
    id: !exists(json, 'id') ? undefined : json['id'],
    mimeType: !exists(json, 'mimeType') ? undefined : json['mimeType'],
    numPages: !exists(json, 'numPages') ? undefined : json['numPages'],
  }
}

export function SupportDocumentsToJSON(value?: SupportDocuments | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    displayLabel: value.displayLabel,
    fieldName: value.fieldName,
    id: value.id,
    mimeType: value.mimeType,
    numPages: value.numPages,
  }
}
