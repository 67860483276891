import React, { CSSProperties } from 'react'
import Button from '@mui/material/Button'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link, To } from 'react-router-dom'
import BaseButton from './BaseButton'
import { useTheme } from '@mui/material'

export enum TextButtonVariant {
  Cancel = 'cancel',
  NoCancel = 'noCancel',
  CompleteInvitation = 'completeInvitation',
  NoOnlyUpdateCommunity = 'noOnlyUpdateCommunity',
  Drop = 'drop',
  Remove = 'remove',
  RemoveAddedStudent = 'removeAddedStudent',
  AddNewStudent = 'addNewStudent',
  Back = 'back',
  EditAddress = 'editAddress',
  Renew = 'renew',
  Resend = 'resend',
  CancelInvitation = 'cancelInvitation',
  ForgotPassword = 'forgotPassword',
  ForgotEmail = 'forgotEmail',
  BackToLogin = 'backToLogin',
  Edit = 'edit',
  ResendCode = 'resendCode',
  EditResend = 'editResend',
  ViewInvoice = 'viewInvoice',
  NoGoBack = 'noGoBack',
  View = 'view',
  ViewProfile = 'view profile',
  MoreInfo = 'moreInfo',
  NoClose = 'noClose',
  DeleteDiscount = 'deleteDiscount',
  DeletePayment = 'deletePayment',
  DeleteRefund = 'deleteRefund',
  VoidPayment = 'voidPayment',
  RestorePayment = 'restorePayment',
  EditAndResend = 'editAndResend',
  CopyToClipboard = 'copyToClipboard',
  Send = 'send',
  Dismiss = 'dismiss',
}

interface TextButtonProps {
  id: string
  variant: TextButtonVariant
  onClick?: () => void
  css?: CSSProperties
  fullWidth?: boolean
  to?: To
  state?: { params: string }
  disabled?: boolean
  /**
   *  A boolean to determine if we use the BaseButton component for async behavior or the button within ContainedButton
   *  THIS IS A TEMPORARY FIX SO WE DON"T HAVE TO BREAK ALL BUTTONS AT ONCE. The idea behind this boolean is to slowly
   *  transition buttons to the async behavior in BaseButton without a massive overhaul. This will eventually be removed
   *  so DO NOT based anything on this other than the ternary, for now.
   */
  useBaseButton?: boolean
  /**
   * LoadingId of the primary button. This will be the operationId of the api call being made
   * concatenated with the Component's name. This is optional only now because we are using useBaseButton.
   * FIXME: When we no longer need base button, require this property.
   *
   * e.g. If this is from AuthFormCard calling the signin endpoint the loadingId will be
   *
   *    signingAuthFormCard
   */
  loadingId?: string
}

const labelForVariant = (variant: TextButtonVariant, t: TFunction) => {
  switch (variant) {
    case TextButtonVariant.Cancel:
    case TextButtonVariant.CancelInvitation:
      return t('Buttons.FormButtons.CancelButton', 'Cancel')
    case TextButtonVariant.CompleteInvitation:
      return t('Buttons.FormButtons.CompleteInvitation', 'Complete Invitation')
    case TextButtonVariant.NoCancel:
      return t('Buttons.FormButtons.ModalCancel', 'No, Cancel')
    case TextButtonVariant.NoOnlyUpdateCommunity:
      return t(
        'Buttons.FormButtons.noOnlyUpdateCommunity',
        'No, Only Update the Community'
      )
    case TextButtonVariant.Drop:
      return t('Buttons.FormButtons.Drop', 'Drop')
    case TextButtonVariant.Remove:
      return t('Buttons.FormButtons.Remove', 'Remove')
    case TextButtonVariant.RemoveAddedStudent:
      return t('Buttons.FormButtons.RemoveAddedStudent', 'Remove Added Student')
    case TextButtonVariant.AddNewStudent:
      return t(
        'Buttons.FormButtons.AddNewStudent',
        'Add a New Student to the Family'
      )
    case TextButtonVariant.Back:
      return t('Buttons.FormButtons.Back', 'Back')
    case TextButtonVariant.EditAddress:
      return t('Buttons.FormButtons.EditAddress', 'Edit Address')
    case TextButtonVariant.Renew:
      return t('Buttons.FormButtons.Renew', 'Renew')
    case TextButtonVariant.Resend:
      return t('Buttons.FormButtons.Resend', 'Resend')
    case TextButtonVariant.EditResend:
        return t('Buttons.FormButtons.Resend', 'Edit/Resend')
    case TextButtonVariant.ForgotPassword:
      return t('Buttons.FormButtons.ForgotPassword', 'Forgot Password?')
    case TextButtonVariant.ForgotEmail:
      return t(
        'Buttons.FormButtons.ForgotEmail',
        'I don’t remember my email address'
      )
    case TextButtonVariant.BackToLogin:
      return t('Buttons.FormButtons.BackToLogin', 'Back To Login')
    case TextButtonVariant.Edit:
      return t('Buttons.FormButtons.Edit', 'Edit')
    case TextButtonVariant.ResendCode:
      return t('Buttons.FormButtons.ResendCode', 'Resend Code')
    case TextButtonVariant.ViewInvoice:
      return t('Buttons.FormButtons.ViewInvoice', 'View Invoice')
    case TextButtonVariant.NoGoBack:
      return t('Buttons.FormButtons.NoGoBack', 'No, Go Back')
    case TextButtonVariant.View:
      return t('Buttons.FormButtons.View', 'View')
    case TextButtonVariant.ViewProfile:
      return t('Buttons.FormButtons.ViewProfile', 'View Profile')
    case TextButtonVariant.MoreInfo:
      return t('Buttons.FormButtons.MoreInfo', 'More Info')
    case TextButtonVariant.NoClose:
      return t('Buttons.FormButtons.NoClose', 'No, Close')
    case TextButtonVariant.DeleteDiscount:
      return t('Buttons.FormButtons.DeleteDiscount', 'Delete Discount')
    case TextButtonVariant.DeletePayment:
      return t('Buttons.FormButtons.DeletePayment', 'Delete Payment')
    case TextButtonVariant.DeleteRefund:
      return t('Buttons.FormButtons.DeleteRefund', 'Delete Refund')
    case TextButtonVariant.VoidPayment:
      return t('Buttons.FormButtons.VoidPayment', 'Void Payment')
    case TextButtonVariant.RestorePayment:
      return t('Buttons.FormButtons.RestorePayment', 'Restore Payment')
    case TextButtonVariant.EditAndResend:
      return t('Buttons.FormButtons.EditAndResend', 'Edit & Resend')
    case TextButtonVariant.CopyToClipboard:
      return t('Buttons.FormButtons.CopyToClipboard', 'Copy To Clipboard')
    case TextButtonVariant.Send:
      return t('Buttons.FormButtons.Send', 'Send')
    case TextButtonVariant.Dismiss:
      return t('Buttons.FormButtons.Dismiss', 'Dismiss')
  }
}

export const TextButton: React.FC<TextButtonProps> = ({
  id,
  variant,
  onClick = undefined,
  css,
  fullWidth,
  to = undefined,
  state = undefined,
  disabled,
  useBaseButton = false,
  loadingId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  let defaultStyles = {
    fontSize: '14px',
    color: theme.palette.primary.light,
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 0),
    },
  } as CSSProperties

  switch (variant) {
    case TextButtonVariant.Drop:
    case TextButtonVariant.Remove:
    case TextButtonVariant.RemoveAddedStudent:
    case TextButtonVariant.CancelInvitation:
    case TextButtonVariant.DeleteDiscount:
    case TextButtonVariant.DeletePayment:
    case TextButtonVariant.DeleteRefund:
    case TextButtonVariant.VoidPayment:
      defaultStyles = {
        ...defaultStyles,
        color: theme.palette.textOrIcon.redButton,
      }
      break
    case TextButtonVariant.Renew:
    case TextButtonVariant.Resend:
    case TextButtonVariant.EditResend:
    case TextButtonVariant.Edit:
    case TextButtonVariant.ViewInvoice:
    case TextButtonVariant.View:
    case TextButtonVariant.ViewProfile:
    case TextButtonVariant.MoreInfo:
    case TextButtonVariant.RestorePayment:
    case TextButtonVariant.EditAndResend:
    case TextButtonVariant.CompleteInvitation:
    case TextButtonVariant.CopyToClipboard:
      defaultStyles = {
        ...defaultStyles,
        color: theme.palette.textOrIcon.greenButton,
      }
      break
    default:
      break
  }

  return useBaseButton ? (
    <BaseButton
      id={id}
      color="secondary"
      variant={'text'}
      // OnClick is then handled in a useEffect where the async behavior occurs. The base button just sets the loadingContext value
      fullWidth={fullWidth}
      disabled={disabled}
      label={labelForVariant(variant, t)}
      css={{
        ...defaultStyles,
        ...css,
      }}
      to={to}
      state={state}
      loadingId={loadingId}
    />
  ) : (
    <Button
      id={id}
      sx={{
        ...defaultStyles,
        ...css,
      }}
      onClick={onClick}
      fullWidth={fullWidth}
      component={to ? Link : 'button'}
      to={to}
      state={state}
      disabled={disabled}
    >
      {labelForVariant(variant, t)}
    </Button>
  )
}

export default TextButton
