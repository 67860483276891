/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementDocumentSelectOption,
  AgreementDocumentSelectOptionFromJSON,
  AgreementDocumentSelectOptionToJSON,
} from './'

/**
 *
 * @export
 * @interface RoleAgreementDocumentSelectOption
 */
export interface RoleAgreementDocumentSelectOption {
  /**
   * The name of the role.
   * @type {string}
   * @memberof RoleAgreementDocumentSelectOption
   */
  roleName: string
  /**
   * Unique identifier for the role.
   * @type {number}
   * @memberof RoleAgreementDocumentSelectOption
   */
  roleKey: number
  /**
   * Unique identifier for the template pertaining to this role, which is a workflowId in Adobe Sign.
   * @type {string}
   * @memberof RoleAgreementDocumentSelectOption
   */
  agreementTemplateId?: string
  /**
   * A list of agreement documents that pertain to the role.
   * @type {Array<AgreementDocumentSelectOption>}
   * @memberof RoleAgreementDocumentSelectOption
   */
  agreementDocuments: Array<AgreementDocumentSelectOption>
  /**
   * Bypasses the adobe sign agreements.
   * @type {boolean}
   * @memberof RoleAgreementDocumentSelectOption
   */
  bypassAutomatedAgreements?: boolean
  /**
   * Determine if field end date will be required on agreement.
   * @type {boolean}
   * @memberof RoleAgreementDocumentSelectOption
   */
  requireEndDate?: boolean
  /**
   * Unique identifier for the internal agreementTemplate id.
   * @type {number}
   * @memberof RoleAgreementDocumentSelectOption
   */
  agreementTemplateKey?: number
}

export function RoleAgreementDocumentSelectOptionFromJSON(
  json: any
): RoleAgreementDocumentSelectOption {
  return RoleAgreementDocumentSelectOptionFromJSONTyped(json, false)
}

export function RoleAgreementDocumentSelectOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RoleAgreementDocumentSelectOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleName: json['roleName'],
    roleKey: json['roleKey'],
    agreementTemplateId: !exists(json, 'agreementTemplateId')
      ? undefined
      : json['agreementTemplateId'],
    agreementDocuments: (json['agreementDocuments'] as Array<any>).map(
      AgreementDocumentSelectOptionFromJSON
    ),
    bypassAutomatedAgreements: !exists(json, 'bypassAutomatedAgreements')
      ? undefined
      : json['bypassAutomatedAgreements'],
    requireEndDate: !exists(json, 'requireEndDate')
      ? undefined
      : json['requireEndDate'],
    agreementTemplateKey: !exists(json, 'agreementTemplateKey')
      ? undefined
      : json['agreementTemplateKey'],
  }
}

export function RoleAgreementDocumentSelectOptionToJSON(
  value?: RoleAgreementDocumentSelectOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleName: value.roleName,
    roleKey: value.roleKey,
    agreementTemplateId: value.agreementTemplateId,
    agreementDocuments: (value.agreementDocuments as Array<any>).map(
      AgreementDocumentSelectOptionToJSON
    ),
    bypassAutomatedAgreements: value.bypassAutomatedAgreements,
    requireEndDate: value.requireEndDate,
    agreementTemplateKey: value.agreementTemplateKey,
  }
}
