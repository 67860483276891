/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  CreateTranscriptYear,
  CreateTranscriptYearFromJSON,
  CreateTranscriptYearToJSON,
} from './'

/**
 *
 * @export
 * @interface CreateTranscript
 */
export interface CreateTranscript {
  /**
   * The name of the transcript. Can be whatever the user wants it to be.
   * @type {string}
   * @memberof CreateTranscript
   */
  transcriptName: string
  /**
   * The primary key for the student.
   * @type {number}
   * @memberof CreateTranscript
   */
  studentKey: number
  /**
   * email of the student
   * @type {string}
   * @memberof CreateTranscript
   */
  studentEmail?: string
  /**
   *
   * @type {Address}
   * @memberof CreateTranscript
   */
  schoolAddress: Address
  /**
   * The date of birth of the student.
   * @type {Date}
   * @memberof CreateTranscript
   */
  studentDateOfBirth?: Date
  /**
   * The name of the school the transcript it from.
   * @type {string}
   * @memberof CreateTranscript
   */
  schoolName: string
  /**
   * Boolean indicating if the school address is the same as the student's address.
   * @type {boolean}
   * @memberof CreateTranscript
   */
  studentAddressSameAsSchool?: boolean
  /**
   *
   * @type {Address}
   * @memberof CreateTranscript
   */
  studentAddress?: Address
  /**
   * The primary key for the grading scale record for the transcript.
   * @type {number}
   * @memberof CreateTranscript
   */
  gradingScaleKey?: number
  /**
   * Date the student entered high school.
   * @type {Date}
   * @memberof CreateTranscript
   */
  dateHighSchoolEntry?: Date
  /**
   * Date the student graduated.
   * @type {Date}
   * @memberof CreateTranscript
   */
  dateGraduation?: Date
  /**
   * Summary of the transcript. Can be whatever the user wants.
   * @type {string}
   * @memberof CreateTranscript
   */
  summary?: string
  /**
   *
   * @type {CreateTranscriptYear}
   * @memberof CreateTranscript
   */
  transcriptYear1?: CreateTranscriptYear
  /**
   *
   * @type {CreateTranscriptYear}
   * @memberof CreateTranscript
   */
  transcriptYear2?: CreateTranscriptYear
  /**
   *
   * @type {CreateTranscriptYear}
   * @memberof CreateTranscript
   */
  transcriptYear3?: CreateTranscriptYear
  /**
   *
   * @type {CreateTranscriptYear}
   * @memberof CreateTranscript
   */
  transcriptYear4?: CreateTranscriptYear
  /**
   * total credits for the entire transcript, this field is calculated on the frontend
   * @type {number}
   * @memberof CreateTranscript
   */
  totalCredits?: number
  /**
   * GPA of the entire transcript, this field is calculated on the frontend
   * @type {number}
   * @memberof CreateTranscript
   */
  gpa?: number
}

export function CreateTranscriptFromJSON(json: any): CreateTranscript {
  return CreateTranscriptFromJSONTyped(json, false)
}

export function CreateTranscriptFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateTranscript {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transcriptName: json['transcriptName'],
    studentKey: json['studentKey'],
    studentEmail: !exists(json, 'studentEmail')
      ? undefined
      : json['studentEmail'],
    schoolAddress: AddressFromJSON(json['schoolAddress']),
    studentDateOfBirth: !exists(json, 'studentDateOfBirth')
      ? undefined
      : new Date(json['studentDateOfBirth']),
    schoolName: json['schoolName'],
    studentAddressSameAsSchool: !exists(json, 'studentAddressSameAsSchool')
      ? undefined
      : json['studentAddressSameAsSchool'],
    studentAddress: !exists(json, 'studentAddress')
      ? undefined
      : AddressFromJSON(json['studentAddress']),
    gradingScaleKey: !exists(json, 'gradingScaleKey')
      ? undefined
      : json['gradingScaleKey'],
    dateHighSchoolEntry: !exists(json, 'dateHighSchoolEntry')
      ? undefined
      : new Date(json['dateHighSchoolEntry']),
    dateGraduation: !exists(json, 'dateGraduation')
      ? undefined
      : new Date(json['dateGraduation']),
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    transcriptYear1: !exists(json, 'transcriptYear1')
      ? undefined
      : CreateTranscriptYearFromJSON(json['transcriptYear1']),
    transcriptYear2: !exists(json, 'transcriptYear2')
      ? undefined
      : CreateTranscriptYearFromJSON(json['transcriptYear2']),
    transcriptYear3: !exists(json, 'transcriptYear3')
      ? undefined
      : CreateTranscriptYearFromJSON(json['transcriptYear3']),
    transcriptYear4: !exists(json, 'transcriptYear4')
      ? undefined
      : CreateTranscriptYearFromJSON(json['transcriptYear4']),
    totalCredits: !exists(json, 'totalCredits')
      ? undefined
      : json['totalCredits'],
    gpa: !exists(json, 'gpa') ? undefined : json['gpa'],
  }
}

export function CreateTranscriptToJSON(value?: CreateTranscript | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transcriptName: value.transcriptName,
    studentKey: value.studentKey,
    studentEmail: value.studentEmail,
    schoolAddress: AddressToJSON(value.schoolAddress),
    studentDateOfBirth:
      value.studentDateOfBirth === undefined
        ? undefined
        : value.studentDateOfBirth.toISOString().substr(0, 10),
    schoolName: value.schoolName,
    studentAddressSameAsSchool: value.studentAddressSameAsSchool,
    studentAddress: AddressToJSON(value.studentAddress),
    gradingScaleKey: value.gradingScaleKey,
    dateHighSchoolEntry:
      value.dateHighSchoolEntry === undefined
        ? undefined
        : value.dateHighSchoolEntry.toISOString().substr(0, 10),
    dateGraduation:
      value.dateGraduation === undefined
        ? undefined
        : value.dateGraduation.toISOString().substr(0, 10),
    summary: value.summary,
    transcriptYear1: CreateTranscriptYearToJSON(value.transcriptYear1),
    transcriptYear2: CreateTranscriptYearToJSON(value.transcriptYear2),
    transcriptYear3: CreateTranscriptYearToJSON(value.transcriptYear3),
    transcriptYear4: CreateTranscriptYearToJSON(value.transcriptYear4),
    totalCredits: value.totalCredits,
    gpa: value.gpa,
  }
}
