/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  EnrollmentDiscountCode,
  EnrollmentDiscountCodeFromJSON,
  EnrollmentDiscountCodeToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 * `discountCode` is present if the enrollment fee was discounted away. Otherwise, the enrollment fee appropriate to the program identified by `programKey` was included in the total paid.
 * @export
 * @interface EnrollmentPaymentDetails
 */
export interface EnrollmentPaymentDetails {
  /**
   *
   * @type {number}
   * @memberof EnrollmentPaymentDetails
   */
  enrollmentPaymentKey: number
  /**
   *
   * @type {number}
   * @memberof EnrollmentPaymentDetails
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof EnrollmentPaymentDetails
   */
  programType?: ProgramType
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof EnrollmentPaymentDetails
   */
  semesterOneStartDate?: Date
  /**
   * The name of the program's community.
   * @type {string}
   * @memberof EnrollmentPaymentDetails
   */
  communityName?: string
  /**
   *
   * @type {number}
   * @memberof EnrollmentPaymentDetails
   */
  studentKey: number
  /**
   *
   * @type {string}
   * @memberof EnrollmentPaymentDetails
   */
  studentFirstName?: string
  /**
   *
   * @type {EnrollmentDiscountCode}
   * @memberof EnrollmentPaymentDetails
   */
  discountCode?: EnrollmentDiscountCode
}

export function EnrollmentPaymentDetailsFromJSON(
  json: any
): EnrollmentPaymentDetails {
  return EnrollmentPaymentDetailsFromJSONTyped(json, false)
}

export function EnrollmentPaymentDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentPaymentDetails {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enrollmentPaymentKey: json['enrollmentPaymentKey'],
    programKey: json['programKey'],
    programType: !exists(json, 'programType')
      ? undefined
      : ProgramTypeFromJSON(json['programType']),
    semesterOneStartDate: !exists(json, 'semesterOneStartDate')
      ? undefined
      : new Date(json['semesterOneStartDate']),
    communityName: !exists(json, 'communityName')
      ? undefined
      : json['communityName'],
    studentKey: json['studentKey'],
    studentFirstName: !exists(json, 'studentFirstName')
      ? undefined
      : json['studentFirstName'],
    discountCode: !exists(json, 'discountCode')
      ? undefined
      : EnrollmentDiscountCodeFromJSON(json['discountCode']),
  }
}

export function EnrollmentPaymentDetailsToJSON(
  value?: EnrollmentPaymentDetails | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enrollmentPaymentKey: value.enrollmentPaymentKey,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    semesterOneStartDate:
      value.semesterOneStartDate === undefined
        ? undefined
        : value.semesterOneStartDate.toISOString().substr(0, 10),
    communityName: value.communityName,
    studentKey: value.studentKey,
    studentFirstName: value.studentFirstName,
    discountCode: EnrollmentDiscountCodeToJSON(value.discountCode),
  }
}
