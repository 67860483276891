import React, { PropsWithChildren } from 'react'
import { StyledMaterialDesignContent } from './NotistackSnackbarKeyProvider'
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack'
import { createMemoryRouter } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import { AppRenderOptions, TestWrapperProps } from '../../testRenderer'
import {
  createPrivateProviders,
  createPublicProviders,
  createRouterProviders,
  withMockComponent,
} from './utils/withMockComponent'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { globalStyles, muiTheme } from '../../stylesheet'
import CookieBanner from '../CookieBanner'
import ContextResetter from './ContextResetter'
import ScrollToTop from '../Routes/ScrollToTop'
import PrivateRoute from '../Routes/PrivateRoute'

const env = process.env.NODE_ENV

export const PublicProviders: React.FC<
  PropsWithChildren & AppRenderOptions
> = ({ children, loadingProviderConfig, countryConfig }) => {
  const isTest = env === 'test'
  const Providers = createPublicProviders({
    loadingProviderConfig,
    countryConfig,
  })
  return (
    <ThemeProvider theme={muiTheme}>
      <Providers>
        {!isTest && <CssBaseline />}
        <>{children}</>
        {!isTest && <CookieBanner />}
      </Providers>
    </ThemeProvider>
  )
}

export const PrivateProviders: React.FC<
  PropsWithChildren & AppRenderOptions
> = ({
  children,
  speedDialMenuConfig,
  shouldMockSpeedDial,
  accountConfig,
  programConfig,
  transcriptProviderConfig,
  eventProviderConfig,
  loadingProviderConfig,
  shouldMockLoading,
  userProviderInitialUser,
  userConfig,
  dashboardProviderConfig,
  countryConfig,
  titleConfig,
}) => {
  const isTest = env === 'test'
  const ScrollToTopWrapper = withMockComponent({
    WrappedComponent: ScrollToTop,
    useMock: isTest,
  })
  const PrivateRouteWrapper = withMockComponent({
    /**
     * privateRoute is a component that returns the country information and login, currently we use
     * loggedInWithPermissions* to initiate the login information to the auth context,
     * returning this component in test environments causes tests to fail because we do not add this data and the privateRoute has its own test.
     * if we are not in testing, we are in development or production.
     */
    WrappedComponent: !isTest ? PrivateRoute : undefined,
    useMock: isTest,
  })
  const ContextResetterWrapper = withMockComponent({
    WrappedComponent: ContextResetter,
    useMock: isTest,
  })

  const { ScrollProvider, LoadingProvider, Providers } = createPrivateProviders(
    {
      speedDialMenuConfig,
      shouldMockSpeedDial,
      accountConfig,
      programConfig,
      transcriptProviderConfig,
      eventProviderConfig,
      loadingProviderConfig,
      shouldMockLoading,
      userProviderInitialUser,
      userConfig,
      dashboardProviderConfig,
      countryConfig,
      titleConfig,
    }
  )

  return (
    <ScrollProvider>
      <ScrollToTopWrapper />
      <ThemeProvider theme={muiTheme}>
        {!isTest && <GlobalStyles styles={globalStyles} />}
        {!isTest && <CssBaseline />}
        <LoadingProvider>
          <PrivateRouteWrapper>
            <Providers>
              <ContextResetterWrapper />
              {children}
            </Providers>
          </PrivateRouteWrapper>
        </LoadingProvider>
      </ThemeProvider>
    </ScrollProvider>
  )
}

export const testRouterType = createMemoryRouter([{}])
export const browserRouterType = createBrowserRouter([{}])

export const RouterProviders: React.FC<
  PropsWithChildren & {
    router: typeof testRouterType | typeof browserRouterType
  } & TestWrapperProps
> = ({
  children,
  router,
  auth,
  notiSnackbarProviderConfig,
  notistackSnackbarKeyConfig,
  shouldMockNotistackSnackbarKey,
  snackbarConfig,
  learningConfig,
  businessConfig,
  communitiesConfig,
  filterConfig,
  shouldMockFilter,
}) => {
  const Providers = createRouterProviders(
    {
      auth,
      notistackSnackbarKeyConfig,
      shouldMockNotistackSnackbarKey,
      snackbarConfig,
      learningConfig,
      businessConfig,
      communitiesConfig,
      filterConfig,
      shouldMockFilter,
    },
    router
  )

  return (
    <ThemeProvider theme={muiTheme}>
      <NotiSnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        maxSnack={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={notiSnackbarProviderConfig?.onClose}
      >
        <Providers>{children}</Providers>
      </NotiSnackbarProvider>
    </ThemeProvider>
  )
}

export default {
  PrivateProviders,
  PublicProviders,
  RouterProviders,
}
