/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Address, AddressFromJSON, AddressToJSON } from './'

/**
 *
 * @export
 * @interface Registrant
 */
export interface Registrant {
  /**
   * A system generated unique identifier.
   * @type {number}
   * @memberof Registrant
   */
  id?: number
  /**
   * The first name provided by the registrant.
   * @type {string}
   * @memberof Registrant
   */
  firstName: string
  /**
   * The last name provided by the registrant.
   * @type {string}
   * @memberof Registrant
   */
  lastName: string
  /**
   * An email address provided by the registrant.
   * @type {string}
   * @memberof Registrant
   */
  email: string
  /**
   * The phone number provided by the registrant.
   * @type {string}
   * @memberof Registrant
   */
  phone?: string
  /**
   *
   * @type {Address}
   * @memberof Registrant
   */
  address?: Address
  /**
   * The unique Event identifier for the registration.
   * @type {string}
   * @memberof Registrant
   */
  eventId: string
  /**
   * Array of the registrant's roles
   * @type {Array<string>}
   * @memberof Registrant
   */
  roles?: Array<string>
  /**
   * Indicates whether the user is attending the event.
   * @type {boolean}
   * @memberof Registrant
   */
  attending?: boolean
  /**
   * The created date for the event in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Registrant
   */
  createdDate?: Date
}

export function RegistrantFromJSON(json: any): Registrant {
  return RegistrantFromJSONTyped(json, false)
}

export function RegistrantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Registrant {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    address: !exists(json, 'address')
      ? undefined
      : AddressFromJSON(json['address']),
    eventId: json['eventId'],
    roles: !exists(json, 'roles') ? undefined : json['roles'],
    attending: !exists(json, 'attending') ? undefined : json['attending'],
    createdDate: !exists(json, 'createdDate')
      ? undefined
      : new Date(json['createdDate']),
  }
}

export function RegistrantToJSON(value?: Registrant | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phone: value.phone,
    address: AddressToJSON(value.address),
    eventId: value.eventId,
    roles: value.roles,
    attending: value.attending,
    createdDate:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString().substr(0, 10),
  }
}
