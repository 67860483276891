/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  SingleActorMetricResult,
  SingleActorMetricResultFromJSON,
  SingleActorMetricResultToJSON,
  SingleDownlineMetricResult,
  SingleDownlineMetricResultFromJSON,
  SingleDownlineMetricResultToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchMetricResponseBody
 */
export interface FetchMetricResponseBody {
  /**
   * Name of the metric
   * @type {string}
   * @memberof FetchMetricResponseBody
   */
  name: string
  /**
   * The actor key that correlates to the metric run.
   * @type {number}
   * @memberof FetchMetricResponseBody
   */
  forActorKey: number
  /**
   * The first name of the user who the actor is for.
   * @type {string}
   * @memberof FetchMetricResponseBody
   */
  forActorFirstName: string
  /**
   * The last name of the user who the actor is for.
   * @type {string}
   * @memberof FetchMetricResponseBody
   */
  forActorLastName: string
  /**
   * The name of the role that the actor is associated with.
   * @type {string}
   * @memberof FetchMetricResponseBody
   */
  forActorRoleName: string
  /**
   *
   * @type {Array<SingleActorMetricResult>}
   * @memberof FetchMetricResponseBody
   */
  actorsMetrics: Array<SingleActorMetricResult>
  /**
   *
   * @type {Array<SingleDownlineMetricResult>}
   * @memberof FetchMetricResponseBody
   */
  downlineMetrics: Array<SingleDownlineMetricResult>
}

export function FetchMetricResponseBodyFromJSON(
  json: any
): FetchMetricResponseBody {
  return FetchMetricResponseBodyFromJSONTyped(json, false)
}

export function FetchMetricResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchMetricResponseBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    forActorKey: json['forActorKey'],
    forActorFirstName: json['forActorFirstName'],
    forActorLastName: json['forActorLastName'],
    forActorRoleName: json['forActorRoleName'],
    actorsMetrics: (json['actorsMetrics'] as Array<any>).map(
      SingleActorMetricResultFromJSON
    ),
    downlineMetrics: (json['downlineMetrics'] as Array<any>).map(
      SingleDownlineMetricResultFromJSON
    ),
  }
}

export function FetchMetricResponseBodyToJSON(
  value?: FetchMetricResponseBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    forActorKey: value.forActorKey,
    forActorFirstName: value.forActorFirstName,
    forActorLastName: value.forActorLastName,
    forActorRoleName: value.forActorRoleName,
    actorsMetrics: (value.actorsMetrics as Array<any>).map(
      SingleActorMetricResultToJSON
    ),
    downlineMetrics: (value.downlineMetrics as Array<any>).map(
      SingleDownlineMetricResultToJSON
    ),
  }
}
