/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface DirectorFilterOption
 */
export interface DirectorFilterOption {
  /**
   * Unique identifier of the director
   * @type {number}
   * @memberof DirectorFilterOption
   */
  directorKey: number
  /**
   * The name of the director
   * @type {string}
   * @memberof DirectorFilterOption
   */
  name: string
  /**
   * The director's role name
   * @type {string}
   * @memberof DirectorFilterOption
   */
  roleName: string
  /**
   * The date in which the actor is valid from in date-time format.
   * @type {Date}
   * @memberof DirectorFilterOption
   */
  validFrom: Date
  /**
   * The date in which the actor becomes invalid on and after in date-time format.
   * @type {Date}
   * @memberof DirectorFilterOption
   */
  validTo: Date
}

export function DirectorFilterOptionFromJSON(json: any): DirectorFilterOption {
  return DirectorFilterOptionFromJSONTyped(json, false)
}

export function DirectorFilterOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DirectorFilterOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    directorKey: json['directorKey'],
    name: json['name'],
    roleName: json['roleName'],
    validFrom: new Date(json['validFrom']),
    validTo: new Date(json['validTo']),
  }
}

export function DirectorFilterOptionToJSON(
  value?: DirectorFilterOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    directorKey: value.directorKey,
    name: value.name,
    roleName: value.roleName,
    validFrom: value.validFrom.toISOString(),
    validTo: value.validTo.toISOString(),
  }
}
