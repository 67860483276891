/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 * Represents an account against which licensing payments can be made.
 * @export
 * @interface LicensingBillingAccount
 */
export interface LicensingBillingAccount {
  /**
   *
   * @type {string}
   * @memberof LicensingBillingAccount
   */
  type: LicensingBillingAccountTypeEnum
  /**
   *
   * @type {number}
   * @memberof LicensingBillingAccount
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof LicensingBillingAccount
   */
  programType?: ProgramType
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof LicensingBillingAccount
   */
  semesterOneStartDate?: Date
  /**
   * The name of the program's community.
   * @type {string}
   * @memberof LicensingBillingAccount
   */
  communityName?: string
}

/**
 * @export
 * @enum {string}
 */
export enum LicensingBillingAccountTypeEnum {
  Licensing = 'Licensing',
}

export function LicensingBillingAccountFromJSON(
  json: any
): LicensingBillingAccount {
  return LicensingBillingAccountFromJSONTyped(json, false)
}

export function LicensingBillingAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingBillingAccount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    programKey: json['programKey'],
    programType: !exists(json, 'programType')
      ? undefined
      : ProgramTypeFromJSON(json['programType']),
    semesterOneStartDate: !exists(json, 'semesterOneStartDate')
      ? undefined
      : new Date(json['semesterOneStartDate']),
    communityName: !exists(json, 'communityName')
      ? undefined
      : json['communityName'],
  }
}

export function LicensingBillingAccountToJSON(
  value?: LicensingBillingAccount | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    semesterOneStartDate:
      value.semesterOneStartDate === undefined
        ? undefined
        : value.semesterOneStartDate.toISOString().substr(0, 10),
    communityName: value.communityName,
  }
}
