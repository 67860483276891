import { Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../Elements/Header'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import PaymentModal from '../../Modals/PaymentModal'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import EnrollmentFeeInfoModal from '../../Modals/EnrollmentFeeInfoModal'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import {
  EnrollmentBill,
  EnrollmentDiscountCode,
  EnrollmentDiscountStatusEnum,
  EnrollmentFee,
  EnrollmentFeeStatusEnum,
  PaymentStartRequestBodyForEnrollments,
  PaymentStatusKey,
} from '../../../swagger'
import BillingHistorySummaryTable, {
  BillingHistorySummaryTableVariant,
} from './BillingHistorySummaryTable'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import AmountDue from './AmountDue'
import { Payment } from '../../Interfaces/Payment'
import LedgerTable, { LedgerRow } from '../../Table/LedgerTable'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../Context/SnackbarContext'
import { useMountEffect } from '../../../hooks/useMountEffect'

interface AnnualEnrollmentPaymentCardProps {
  enrollmentPaymentHistory: Payment[]
  enrollmentBill?: EnrollmentBill
  achDiscount: number
  refetch?: () => void
}

export const AnnualEnrollmentPaymentCard: React.FC<
  AnnualEnrollmentPaymentCardProps
> = ({ enrollmentPaymentHistory, enrollmentBill, achDiscount, refetch }) => {
  const { t } = useTranslation()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()
  const defaultCurrencyCode = 'USD'

  const showAnnualEnrollmentPayment = enrollmentBill !== undefined

  /** State Objects */
  const amountDue = enrollmentBill?.total.amount ?? 0
  const currencyCode = enrollmentBill?.total.currencyCode ?? defaultCurrencyCode
  const allEnrollments = enrollmentBill?._for?.enrollments ?? []
  const isTextColorRed = enrollmentBill?.textColor === 'Red'
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [isEnrollmentFeeInfoModalOpen, setIsEnrollmentFeeInfoModalOpen] =
    useState(false)

  const unpaidEnrollments: PaymentStartRequestBodyForEnrollments[] | undefined =
    enrollmentBill?.fees.filter(
      (fee) => fee.status === EnrollmentFeeStatusEnum.Unpaid
    )

  /** Other Objects */
  const enrollmentFeeMap = new Map<
    string /** studentKey-programKey*/,
    EnrollmentFee
  >()
  let unpaidPrograms = 0
  enrollmentBill?.fees.forEach((fee) => {
    if (fee.status === EnrollmentFeeStatusEnum.Unpaid) {
      unpaidPrograms++
    }
    enrollmentFeeMap.set(`${fee.studentKey}-${fee.programKey}`, fee)
  })

  let appliedDiscounts = 0
  enrollmentBill?.discounts?.forEach((discount) => {
    if (discount.status === EnrollmentDiscountStatusEnum.Applied) {
      appliedDiscounts++
    }
  })

  const numberOfPaidProgramsToApplyAchDiscount =
    unpaidPrograms - appliedDiscounts
  /**
   * FIXME: Hotfix to use the last enrollment, meaning the newest enrollment
   * which is most likely to be in a state other than DROP, and also most likely
   * to be needing payment.
   *
   * A scenario where this might not be true is dropping this enrollment after
   * invitation completion and before paying. Since this case is rather specific
   * and requires newer enrollments to be dropped prior to paying we expect less
   * issues with fetching a program in which a parent has no business.
   */
  const programKey = allEnrollments[allEnrollments.length - 1]?.programKey

  const enrollmentTransactions: LedgerRow[] = allEnrollments.map(
    (enrollment) => {
      // A unique key of student program pairing.
      const rowKey = `${enrollment.studentKey}-${enrollment.programKey}`
      const rowTotal = enrollmentFeeMap.get(rowKey)
      let cashAmount = '-'
      if (!!rowTotal) {
        cashAmount =
          rowTotal.status === EnrollmentFeeStatusEnum.Unpaid
            ? getLocaleCurrencyForAmount(
                rowTotal.total.amount,
                rowTotal.total.currencyCode
              )
            : EnrollmentFeeStatusEnum.Paid
      }

      return {
        cells: [
          {
            content:
              enrollment.program +
              ' (' +
              enrollment.semesterOneStartDate?.toISOString().split('T')[0] +
              ')',
            align: 'left',
            color: 'medium',
          },
          {
            content: enrollment.firstName,
            align: 'left',
            color: 'medium',
          },
          {
            content: `${cashAmount}`,
            align: 'right',
            color:
              cashAmount === EnrollmentFeeStatusEnum.Paid ? 'medium' : 'main',
          },
        ],
      }
    }
  )

  const discounts: LedgerRow[] =
    enrollmentBill?.discounts?.map((discount) => {
      let discountMessage = ''
      const discountAmount =
        discount.status === EnrollmentDiscountStatusEnum.Paid
          ? discount.status
          : `-${getLocaleCurrencyForAmount(
              discount.total.amount,
              discount.total.currencyCode
            )}`

      if (discount.code === EnrollmentDiscountCode._1Pp) {
        discountMessage = `${t(
          'AnnualEnrollmentPaymentCard.Discount.MoreThanOneStudent',
          'Discount: More than one student in the same program'
        )}: ${discount.programType}`
      } else {
        discountMessage = t(
          'AnnualEnrollmentPaymentCard.Discount.MaxThreeStudents',
          'Discount Applied: Membership Fee maximum has been reached for this family.'
        )
      }
      const cells: LedgerRow = {
        cells: [
          {
            content: `${discountMessage}`,
            align: 'left',
            colSpan: 2,
          },
          {
            content: discountAmount,
            align: 'right',
          },
        ],
        options: {
          showAsGreenText: true,
        },
      }

      return cells
    }) ?? []

  const isAnyEnrollmentPaymentInError = enrollmentPaymentHistory.some(
    (payment) => {
      return payment.paymentStatusKey === PaymentStatusKey.Err
    }
  )

  const errorCannotMakePayment = t(
    'Account.BillingTab.CannotMakePayments.ErrorMessage',
    'An error has been identified with your transaction. Kindly reach out to our Customer Support Representative for assistance.'
  )

  useMountEffect(() => {
    if (isAnyEnrollmentPaymentInError) {
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorCannotMakePayment)
      setSnackbarState(true)
    }
  })

  /** Hooks */

  /** Methods */
  const onCloseEnrollmentFeeInfoModal = () => {
    setIsEnrollmentFeeInfoModalOpen(false)
  }

  return (
    <section aria-labelledby="annualMembershipFee">
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => {
          setIsPaymentModalOpen(false)
          refetch?.()
        }}
        unpaidEnrollments={unpaidEnrollments}
        amountDue={enrollmentBill?.total ?? { amount: 0, currencyCode: 'USD' }}
        achDiscount={{
          amount: achDiscount,
          applyDiscountTimes: numberOfPaidProgramsToApplyAchDiscount,
          displayMessage: true,
        }}
        programKey={programKey}
      />
      <EnrollmentFeeInfoModal
        isOpen={isEnrollmentFeeInfoModalOpen}
        onClose={onCloseEnrollmentFeeInfoModal}
      />
      <Header
        id="annualMembershipFee"
        headerName={t(
          'AnnualEnrollmentPaymentCard.Heading.AnnualMembershipFee',
          'Annual Membership Fee'
        )}
        component="h6"
      />
      <Card aria-labelledby="annualEnrollmentPayment" component={Paper}>
        {showAnnualEnrollmentPayment && (
          <Box m={3}>
            <SpaceBetweenSection
              left={
                <Box maxWidth={showOnDesktop ? '516px' : '100%'}>
                  <AmountDue
                    amountDue={amountDue}
                    currencyCode={currencyCode}
                    isEnrollmentAmount
                    isTextColorRed={isTextColorRed}
                  />
                  <Box mt={3.25}>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color={theme.palette.textOrIcon.subheader}
                    >
                      {t(
                        'AnnualEnrollmentPaymentCard.PostPaymentInstructions',
                        "Once your program membership fee is paid, you'll enjoy a preview of selected academic content in the CC Connected Learning Center. To access the full range of CC Connected content, pay program tuition and fees to your program Director."
                      )}
                    </Typography>
                  </Box>
                </Box>
              }
              right={
                <Box mt={showOnDesktop ? 0 : 1}>
                  <ContainedButton
                    id="payEnrollmentFee"
                    variant={ContainedButtonVariant.ConfirmEnrollment}
                    disabled={
                      (!amountDue && !unpaidEnrollments?.length) ||
                      isAnyEnrollmentPaymentInError
                    }
                    onClick={() => setIsPaymentModalOpen(true)}
                    fullWidth={!showOnDesktop}
                  />
                </Box>
              }
            />
            <Box maxWidth={516}>
              <LedgerTable
                tableHeaders={[
                  {
                    label: t('Payments.Table.Header.Programs', 'Programs'),
                    align: 'left',
                    cssProps: {
                      color: theme.palette.primary.main,
                      paddingLeft: 0,
                    },
                  },
                  {
                    label: '',
                    align: 'left',
                    id: 'studentName',
                  },
                  {
                    label: '',
                    align: 'left',
                    id: 'amount',
                  },
                ]}
                rows={[...enrollmentTransactions, ...discounts]}
              />
            </Box>
          </Box>
        )}
        <Box ml={3}>
          <Header
            id="annualEnrollmentPaymentTransactions"
            headerName={t(
              'AnnualEnrollmentPaymentCard.Heading.AnnualEnrollmentPaymentTransactions',
              'Transactions'
            )}
            component="h6"
          />
        </Box>
        <BillingHistorySummaryTable
          variant={BillingHistorySummaryTableVariant.EnrollmentPayments}
          ariaLabelledBy="annualEnrollmentPaymentTransactions"
          paymentHistory={enrollmentPaymentHistory}
          amountDue={1515}
        />
      </Card>
    </section>
  )
}

export default AnnualEnrollmentPaymentCard
