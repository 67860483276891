/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: cfcb36c6 (Thu Jan 23 18:58:35 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementRecipient,
  AgreementRecipientFromJSON,
  AgreementRecipientToJSON,
  AgreementTemplateFormField,
  AgreementTemplateFormFieldFromJSON,
  AgreementTemplateFormFieldToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementTemplateFormFields
 */
export interface AgreementTemplateFormFields {
  /**
   * List of form fields pertaining to the recipients the agreement will be sent to.
   * @type {Array<AgreementRecipient>}
   * @memberof AgreementTemplateFormFields
   */
  formRecipients?: Array<AgreementRecipient>
  /**
   * List of form fields that will act as prefill values for fields within the agreement.
   * @type {Array<AgreementTemplateFormField>}
   * @memberof AgreementTemplateFormFields
   */
  formData?: Array<AgreementTemplateFormField>
}

export function AgreementTemplateFormFieldsFromJSON(
  json: any
): AgreementTemplateFormFields {
  return AgreementTemplateFormFieldsFromJSONTyped(json, false)
}

export function AgreementTemplateFormFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementTemplateFormFields {
  if (json === undefined || json === null) {
    return json
  }
  return {
    formRecipients: !exists(json, 'formRecipients')
      ? undefined
      : (json['formRecipients'] as Array<any>).map(AgreementRecipientFromJSON),
    formData: !exists(json, 'formData')
      ? undefined
      : (json['formData'] as Array<any>).map(
          AgreementTemplateFormFieldFromJSON
        ),
  }
}

export function AgreementTemplateFormFieldsToJSON(
  value?: AgreementTemplateFormFields | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    formRecipients:
      value.formRecipients === undefined
        ? undefined
        : (value.formRecipients as Array<any>).map(AgreementRecipientToJSON),
    formData:
      value.formData === undefined
        ? undefined
        : (value.formData as Array<any>).map(AgreementTemplateFormFieldToJSON),
  }
}
